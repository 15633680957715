import { Fragment, ReactElement, ReactNode, useRef, useState } from "react";
import { SectionSubHeading } from "../../Block/SectionHeading/SectionHeading";
import { videoGalleryItem } from "../../Block/VideoGallery/types";
import Icon from "../../Element/Icon/icon";
import { TextLink } from "../../Element/Typography";
import { BaseTeaserData } from "../../Utility/dataTypes";
import NaturalCarousel, {
  ArrowVariant,
  NaturalCarouselRef,
} from "../../Utility/NaturalCarousel/NaturalCarousel";
import Strip from "./Strip";
import VideoGalleryItem from "./VideoGalleryItem";
import styles from "./videogallerylandscape.module.css";

export type ItemProps = videoGalleryItem & {
  src?: string;
  alt?: string;
  caption?: string;
  sections?: BaseTeaserData["sections"];
  publishedDate?: string;
  srcWidth?: number;
  srcHeight?: number;
  description?: string;
};

export type VideoGalleryLandscapeProps = {
  title: string;
  description?: string;
  items: ItemProps[];
  heading?: {
    leftIcon?: ReactElement;
    rightIcon?: ReactElement;
    withDivider?: boolean;
    hasArrows?: boolean;
  };
  arrowSize?: number;
  arrowVariant?: ArrowVariant;
  strip?: {
    dividerVariant?: "primary" | "secondary";
    arrowSize?: number;
    arrowVariant?: ArrowVariant;
  };
  mobileLoopingArrowsBelow?: {
    showArrows?: boolean;
    leftIcon: ReactElement;
    rightIcon: ReactElement;
  };
  cta?: {
    name: string;
    url: string;
    icon: ReactNode;
  };
  Imu?: ReactNode;
  hasLabelContainer?: boolean;
  hasPagination?: boolean;
  hasSummaryItemPublishedDate?: boolean;
  hasStripLabel?: boolean;
};

// const { VideoGalleryLandscapeSmall } = VideoGallery;

/**
 * Description:
 * This component is used when rendering VideoGallery, with Spotlight and Strip.
 * When video in Strip is clicked, the Spotlight will change to the clicked video.
 */
export default function VideoGalleryLandscape({
  title,
  description,
  items,
  heading,
  strip,
  mobileLoopingArrowsBelow,
  cta,
  Imu,
  hasLabelContainer = true,
  hasStripLabel,
  hasPagination = true,
  hasSummaryItemPublishedDate = true,
  arrowSize,
  arrowVariant,
}: VideoGalleryLandscapeProps): ReactElement {
  const carouselRef = useRef<NaturalCarouselRef>(null);
  const [position, setPosition] = useState(0);
  const showHeadingArrows = !!heading?.hasArrows;

  const handleClickCarousel = (pos: "prev" | "next" | number) => {
    if (!carouselRef.current) return;
    if (pos === "prev") {
      carouselRef.current.previous();
    } else if (pos === "next") {
      carouselRef.current.next();
    } else {
      carouselRef.current.goTo(pos);
    }
  };

  const HeadingArrows = showHeadingArrows && (
    <Fragment>
      <button
        className={styles.mDownVisible}
        onClick={() => handleClickCarousel("prev")}
      >
        <Icon>{heading?.leftIcon}</Icon>
      </button>
      <button
        className={styles.mDownVisible}
        onClick={() => handleClickCarousel("next")}
      >
        <Icon>{heading?.rightIcon}</Icon>
      </button>
    </Fragment>
  );

  return (
    <div className={styles.container}>
      {heading && title && (
        <div className={styles.headingContainer}>
          <SectionSubHeading
            title={title}
            description={description}
            arrows={HeadingArrows}
            withDivider={heading.withDivider}
          />
        </div>
      )}

      <div className={styles.bodyContainer}>
        <div className={styles.carouselContainer}>
          <div className={styles.spotlightContainer}>
            <NaturalCarousel
              isLooping={false}
              onPositionChange={setPosition}
              ref={carouselRef}
              arrowVariant={arrowVariant || "drop"} // Default for all pubs
              arrowSize={arrowSize}
            >
              {items?.map((item, index: number) => (
                <VideoGalleryItem
                  key={index}
                  item={item}
                  index={index}
                  itemsLength={items.length}
                  position={position}
                  hasPagination={hasPagination}
                  hasSummaryItemPublishedDate={hasSummaryItemPublishedDate}
                  hasLabelContainer={hasLabelContainer}
                />
              ))}
            </NaturalCarousel>
          </div>

          {strip && (
            <Strip
              items={items}
              gotoPosition={position}
              setGotoPosition={(position: number) =>
                handleClickCarousel(position)
              }
              dividerVariant={strip.dividerVariant}
              arrowSize={strip.arrowSize}
              arrowVariant={strip.arrowVariant || "gradient"} // Default for all pubs
              hasLabel={hasStripLabel}
            />
          )}

          {mobileLoopingArrowsBelow?.showArrows && (
            <div className={styles.mobileLoopingArrowsBelow}>
              <button
                className={styles.mobileLoopingArrow}
                onClick={() => handleClickCarousel("prev")}
              >
                {mobileLoopingArrowsBelow.leftIcon}
              </button>
              <button
                className={styles.mobileLoopingArrow}
                onClick={() => handleClickCarousel("next")}
              >
                {mobileLoopingArrowsBelow.rightIcon}
              </button>
            </div>
          )}

          {cta && (
            <div className={styles.ctaContainer}>
              <TextLink href={cta.url}>
                {cta.name}
                {cta.icon}
              </TextLink>
            </div>
          )}
        </div>
        {Imu && <div className={styles.imuContainer}>{Imu}</div>}
      </div>
    </div>
  );
}
