import { configBrightcove } from "@pub/config";

import {
  ResolvedBrightcoveCUEElement,
  ResolvedCUEElement,
  ResolvedYouTubeCUEElement,
} from "../layouts/Article/elements";

export const getBrightcoveEmbedUrl = (
  element: ResolvedBrightcoveCUEElement,
) => {
  return `https://players.brightcove.net/${configBrightcove.accountId}/default_default/index.html?videoId=${element.additionalFields.brightcoveId?.value}`;
};

export const getYouTubeEmbedUrl = (element: ResolvedYouTubeCUEElement) => {
  const id = element.additionalFields.youtubeId?.value;
  if (id?.match(/^https?:\/\//)) {
    return id;
  }
  return `https://www.youtube.com/embed/${id}?enablejsapi=1`;
};

export const getEmbedUri = (videoElement?: ResolvedCUEElement) => {
  if (videoElement?.type === "embed") {
    return videoElement.additionalFields.uri?.value;
  }
  if (videoElement?.type === "brightcove_video") {
    return getBrightcoveEmbedUrl(videoElement);
  }
  if (videoElement?.type === "youtube_video") {
    return getYouTubeEmbedUrl(videoElement);
  }
};
