export const starRatingIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="24"
    viewBox="0 0 23 24"
    fill="none"
  >
    <path
      d="M10.5489 3.42705C10.8483 2.50574 12.1517 2.50574 12.4511 3.42705L13.8574 7.75532C13.9913 8.16734 14.3752 8.4463 14.8085 8.4463H19.3595C20.3282 8.4463 20.731 9.68592 19.9473 10.2553L16.2654 12.9303C15.9149 13.185 15.7683 13.6364 15.9021 14.0484L17.3085 18.3766C17.6078 19.298 16.5533 20.0641 15.7696 19.4947L12.0878 16.8197C11.7373 16.565 11.2627 16.565 10.9122 16.8197L7.23037 19.4947C6.44665 20.0641 5.39217 19.298 5.69153 18.3766L7.09787 14.0484C7.23174 13.6364 7.08508 13.185 6.7346 12.9303L3.05275 10.2553C2.26904 9.68592 2.67181 8.4463 3.64053 8.4463H8.19155C8.62477 8.4463 9.00873 8.16734 9.1426 7.75532L10.5489 3.42705Z"
      fill="#CACACA"
    />
  </svg>
);

export const aiStarIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="32"
    viewBox="0 0 28 32"
    fill="none"
  >
    <g filter="url(#filter0_f_2851_1187)">
      <path
        d="M26.0002 21C22.4117 21 19.5002 17.8645 19.5002 14C19.5002 17.8645 16.5887 21 13.0002 21C16.5887 21 19.5002 24.1355 19.5002 28C19.5002 24.1355 22.4117 21 26.0002 21Z"
        fill="url(#paint0_diamond_2851_1187)"
        fillOpacity="0.75"
      />
    </g>
    <path
      d="M22.6458 21.1531C20.8256 21.1531 19.3488 19.5339 19.3488 17.5381C19.3488 19.5339 17.8721 21.1531 16.0519 21.1531C17.8721 21.1531 19.3488 22.7724 19.3488 24.7682C19.3488 22.7724 20.8256 21.1531 22.6458 21.1531Z"
      fill="white"
    />
    <g filter="url(#filter1_f_2851_1187)">
      <path
        d="M18 23.5C13.5834 23.5 10 19.6927 10 15C10 19.6927 6.41662 23.5 2 23.5C6.41662 23.5 10 27.3073 10 32C10 27.3073 13.5834 23.5 18 23.5Z"
        fill="url(#paint1_diamond_2851_1187)"
        fillOpacity="0.7"
      />
    </g>
    <path
      d="M14.1431 23.6874C11.8949 23.6874 10.0708 21.6873 10.0708 19.2222C10.0708 21.6873 8.24675 23.6874 5.99855 23.6874C8.24675 23.6874 10.0708 25.6874 10.0708 28.1526C10.0708 25.6874 11.8949 23.6874 14.1431 23.6874Z"
      fill="white"
    />
    <g filter="url(#filter2_f_2851_1187)">
      <path
        d="M25.5989 11.8824C19.4286 11.8824 14.4224 6.55997 14.4224 0C14.4224 6.55997 9.41621 11.8824 3.24594 11.8824C9.41621 11.8824 14.4224 17.2047 14.4224 23.7647C14.4224 17.2047 19.4286 11.8824 25.5989 11.8824Z"
        fill="url(#paint2_diamond_2851_1187)"
        fillOpacity="0.65"
      />
    </g>
    <g filter="url(#filter3_i_2851_1187)">
      <path
        d="M20.2024 11.7457C16.9792 11.7457 14.3641 8.99293 14.3641 5.6001C14.3641 8.99293 11.749 11.7457 8.52579 11.7457C11.749 11.7457 14.3641 14.4984 14.3641 17.8913C14.3641 14.4984 16.9792 11.7457 20.2024 11.7457Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_2851_1187"
        x="11.5002"
        y="12.5"
        width="16"
        height="17"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="0.75"
          result="effect1_foregroundBlur_2851_1187"
        />
      </filter>
      <filter
        id="filter1_f_2851_1187"
        x="0"
        y="13"
        width="20"
        height="21"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="1"
          result="effect1_foregroundBlur_2851_1187"
        />
      </filter>
      <filter
        id="filter2_f_2851_1187"
        x="1.24585"
        y="-2"
        width="26.353"
        height="27.7646"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="1"
          result="effect1_foregroundBlur_2851_1187"
        />
      </filter>
      <filter
        id="filter3_i_2851_1187"
        x="8.52588"
        y="5.6001"
        width="11.6765"
        height="12.291"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2851_1187"
        />
      </filter>
      <radialGradient
        id="paint0_diamond_2851_1187"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(19.7048 20.779) rotate(51.5412) scale(6.98677 6.55426)"
      >
        <stop offset="0.229002" stopColor="#F428AE" />
        <stop offset="0.833379" stopColor="white" />
      </radialGradient>
      <radialGradient
        id="paint1_diamond_2851_1187"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(9.63338 23.3162) rotate(57.4453) scale(10.1765 9.36803)"
      >
        <stop offset="0.055" stopColor="#F428AE" />
        <stop offset="0.635868" stopColor="white" />
      </radialGradient>
      <radialGradient
        id="paint2_diamond_2851_1187"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(14.5002 12) rotate(63.435) scale(7.82624 9.10176)"
      >
        <stop offset="0.122685" stopColor="#F428AE" />
        <stop offset="1" stopColor="#F5F5F5" />
      </radialGradient>
    </defs>
  </svg>
);
