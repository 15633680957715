import { configError } from "@pub/config";

export const textForStatusCode = {
  400: {
    pageTitle:
      configError.messages?.status400?.pageTitle || "Page not available",
    title:
      configError.messages?.status400?.title ||
      "We are sorry, the page you are looking for cannot be served.",
    altTitle: configError.messages?.status404?.altTitle,
    lead:
      configError.messages?.status400?.lead ||
      "Please ensure you are eligible to request this page.",
  },
  404: {
    pageTitle: configError.messages?.status404?.pageTitle || "Page not found",
    title:
      configError.messages?.status404?.title ||
      "We are sorry, the page you are looking for cannot be found.",
    altTitle: configError.messages?.status404?.altTitle,
    lead: configError.messages?.status404?.lead || (
      <>
        Head to our{" "}
        <a href="/">
          <u>home page</u>
        </a>{" "}
        or try searching again.
      </>
    ),
  },
  500: {
    pageTitle: configError.messages?.status500?.pageTitle || "Unexpected Error",
    title:
      configError.messages?.status500?.title || "Oops, something went wrong.",
    altTitle: configError.messages?.status404?.altTitle,
    lead:
      configError.messages?.status500?.lead ||
      "Try refreshing this page or contact us if the problem persists.",
  },
  clientSideError: {
    pageTitle:
      configError.messages?.clientSideError?.pageTitle || "Unexpected Error",
    title:
      configError.messages?.clientSideError?.title ||
      "Oops, something went wrong.",
    altTitle: configError.messages?.status404?.altTitle,
    lead:
      configError.messages?.clientSideError?.lead ||
      "Try refreshing this page, updating your browser or contact us if the problem persists.",
  },
  503: {
    pageTitle:
      configError.messages?.status503?.pageTitle || "Service Unavailable",
    title:
      configError.messages?.status503?.title ||
      "Oops, something took too long.",
    altTitle: configError.messages?.status404?.altTitle,
    lead:
      configError.messages?.status503?.lead ||
      "Try refreshing this page after 30 seconds or contact us if the problem persists.",
  },
};

// Extracting Types From Arrays
type PossibleResponseTexts = typeof textForStatusCode;
export type PossibleText = PossibleResponseTexts[keyof PossibleResponseTexts];
