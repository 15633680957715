import ErrorLayout from "@app/layouts/ErrorLayout/ErrorLayout";
import MetaData from "@components/Modules/MetaData";
import PubBaseLayout from "@pub/BaseLayout";
import {
  CustomContext,
  ResponseType,
  TRouteWithRedirect,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import AboutUsLayout from "@src/app/layouts/AboutUs/AboutUsLayout";
import { StaticContent } from "@src/app/types/ServerCustomContext";
import { ReactNode } from "react";

type AboutUsData = StaticContent;

export default function AboutUsPage(): ReactNode {
  const routeContext: CustomContext<TRouteWithRedirect<AboutUsData, string>> =
    useRouteContext();

  const { context: response } = routeContext;

  if (response.type !== ResponseType.SUCCESS) {
    return <ErrorLayout response={response} />;
  }

  const { processedElements, path, seoFields, ogFields, displaySetting } =
    response.payload;
  const { headline, headlineZH, standfirst, coverImage, bodyElements } =
    processedElements;

  const title = seoFields.metaTitle || headline;
  const description = seoFields.metaDescription;
  const robots =
    seoFields.metaRobots === "1"
      ? "noindex, nofollow"
      : "index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1";

  return (
    <PubBaseLayout ads={false}>
      <MetaData
        title={title}
        type="WebPage"
        path={path}
        image={coverImage?.crops.landscape}
        description={description}
        robots={robots}
        ogFields={ogFields}
      />
      <AboutUsLayout
        headline={headline}
        headlineZH={headlineZH}
        standfirst={standfirst}
        coverImage={coverImage}
        bodyElements={bodyElements}
        path={path}
        displaySetting={displaySetting}
      />
    </PubBaseLayout>
  );
}
