import { configSite } from "@pub/config";
import { Helmet } from "react-helmet-async";

import generateJsonLd from "./jsonLd";
import { MetaDataProps } from "./types";

function MetaData(props: MetaDataProps) {
  const {
    type,
    title,
    description,
    image,
    path,
    ogFields,
    robots = "index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1",
    canonicalUrl: canonicalUrlProp,
  } = props;
  const url = `${configSite.address}${path}`;

  const canonicalUrl = canonicalUrlProp
    ? canonicalUrlProp.match(/^https?:\/\//)
      ? canonicalUrlProp
      : `${configSite.address}${
          canonicalUrlProp.startsWith("/")
            ? canonicalUrlProp
            : `/${canonicalUrlProp}`
        }`
    : url;

  const ogImage = ogFields?.image || image;

  return (
    <Helmet>
      <title>
        {title} | {configSite.name}
      </title>
      <meta name="description" content={description} />
      <meta name="robots" content={robots} />
      <link rel="canonical" href={canonicalUrl} />
      <meta property="og:locale" content="en_SG" />
      <meta
        property="og:type"
        content={type === "Article" ? "article" : "website"}
      />
      <meta property="og:title" content={ogFields?.title || title} />
      <meta
        property="og:description"
        content={ogFields?.description || description}
      />
      <meta property="og:url" content={url} />

      {ogImage && <meta property="og:image" content={ogImage.src} />}
      {ogImage?.srcWidth && (
        <meta property="og:image:width" content={String(ogImage.srcWidth)} />
      )}
      {ogImage?.srcHeight && (
        <meta property="og:image:height" content={String(ogImage.srcHeight)} />
      )}

      <meta property="og:site_name" content={configSite.name} />
      <meta name="twitter:card" content="summary_large_image" />
      {configSite.twitterSite && (
        <meta name="twitter:site" content={configSite.twitterSite} />
      )}
      {/* phone number detection causes hydration issues with React and does not work after client hydration */}
      <meta name="format-detection" content="telephone=no" />

      <script type="application/ld+json">
        {JSON.stringify(
          generateJsonLd({
            path,
            metaData: props,
            canonicalUrl,
          }),
        )}
      </script>
    </Helmet>
  );
}

export default MetaData;
