import { configHome, configTeaser, DataSource } from "@pub/config";
import placeholders from "@pub/placeholders";
import {
  Ads,
  ContentBlock,
  LatestArticle,
  SectionContext,
  useMediaQuery,
} from "@sphtech/dmg-design-system";
import cx from "classnames";
import { ReactNode } from "react";

import styles from "../homelayout.module.css";
import { extractHomeContentData, HomeLayoutProps } from "./utils";

type Props = {
  data: HomeLayoutProps["data"];
  dataSource: DataSource;
  alternateBg?: boolean;
};

/**
 * Description: Content Block G (4 tiles with IMU)
 */
export default function HomeContentBlockG({
  data,
  dataSource,
  alternateBg,
}: Props): ReactNode {
  const contentBlockG = extractHomeContentData(data, dataSource);
  const mobileUp = useMediaQuery("screen and (min-width: 1000px)");

  return (
    contentBlockG && (
      <div
        className={cx(
          alternateBg && styles.backgroundFill,
          styles.gutter,
          styles.contentBlockGContainer,
        )}
        style={
          contentBlockG.config && {
            background: contentBlockG.config.background,
          }
        }
      >
        <div className={cx(styles.bodyContainer)}>
          <ContentBlock
            heading={contentBlockG.title}
            headingZH={contentBlockG.titleZH}
            cta={{
              name: configHome.contentBlockF?.viewMore?.text || "VIEW ALL",
              url: contentBlockG.url,
              icon: configHome.viewMore?.icon,
            }}
            imu={
              !configHome.contentBlockG?.hideIMU ||
              (configHome.contentBlockG.mobileOnlyimuAtIndex && mobileUp) ? (
                <Ads.AdsSideBar />
              ) : null
            }
            withDivider={configHome.headingDivider}
            isHeadingClickable={configHome.isHeadingClickable}
          >
            <SectionContext.Provider
              value={{
                uniqueName: configHome.contentBlockG?.skipSectionContext
                  ? undefined
                  : contentBlockG.uniqSectionName,
              }}
            >
              <div className={cx(styles.latestTeaserFontOverride, styles.row)}>
                {contentBlockG.items.map((item, index) => {
                  return (
                    <div key={index} className={cx(styles.column)}>
                      {configHome.contentBlockG?.mobileOnlyimuAtIndex ===
                        index && (
                        <div>
                          <Ads.AdsSideBarMobile />
                        </div>
                      )}
                      <LatestArticle
                        {...item}
                        mode={
                          configHome.contentBlockG?.landscapeIndexes?.includes(
                            index,
                          )
                            ? "landscape"
                            : configHome.contentBlockG?.originalIndexes?.includes(
                                  index,
                                )
                              ? "original"
                              : "portrait"
                        }
                        mobileMode={configHome.contentBlockG?.mobileMode}
                        placeholders={placeholders}
                        landscapeImageWidth={
                          configHome.contentBlockG?.landscapeImageWidth || 315
                        }
                        portraitImageWidth={
                          configHome.contentBlockG?.portraitImageWidth || 315
                        }
                        displayPublishDate={configTeaser.publishDate}
                        mobileUpAspectRatio={
                          configHome.contentBlockG?.originalIndexes?.includes(
                            index,
                          )
                            ? configHome.contentBlockG
                                .originalMobileUpAspectRatio
                            : undefined
                        }
                        aspectRatio={
                          configHome.contentBlockG?.originalIndexes?.includes(
                            index,
                          )
                            ? configHome.contentBlockG.originalAspectRatio
                            : undefined
                        }
                      />
                    </div>
                  );
                })}
              </div>
            </SectionContext.Provider>
          </ContentBlock>
        </div>
      </div>
    )
  );
}
