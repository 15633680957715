import ErrorLayout from "@app/layouts/ErrorLayout/ErrorLayout";
import LatestStoryLayout from "@app/layouts/LatestStory/LatestStoryLayout";
import { SectionData } from "@app/types/ServerCustomContext";
import AdSettings from "@app/utils/AdSettings";
import {
  GA4_REF_CODE_ANCHORBLOCK_B,
  GA4_REF_CODE_CONTENTBLOCK_L,
  GA4_REF_CODE_TITLE_STACKED,
} from "@app/utils/ga/ref-code";
import {
  mapTeaserDataToAnchor,
  mapTeaserDataToLatestProps,
} from "@app/utils/mapTeaserDataToProps";
import MetaData from "@components/Modules/MetaData";
import PubBaseLayout from "@pub/BaseLayout";
import { configLatestStory } from "@pub/config";
import {
  CustomContext,
  ResponseType,
  TRouteWithRedirect,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import { useLocation } from "react-router-dom";

export default function LatestStory() {
  const routeContext: CustomContext<TRouteWithRedirect<SectionData, string>> =
    useRouteContext();

  // Context from server.ts
  const { context: response } = routeContext;
  const location = useLocation();

  if (response.type !== ResponseType.SUCCESS) {
    return <ErrorLayout response={response} />;
  }

  const data = response.payload;
  const topStory = configLatestStory.hasAnchorBlock && data.topStories[0];
  const latestArticle = configLatestStory.hasAnchorBlock
    ? data.topStories.slice(1)
    : data.topStories;

  return (
    <PubBaseLayout>
      <MetaData type="CollectionPage" title={data.name} path={data.path} />
      <AdSettings
        pageType="latest"
        sectionName="latest-stories"
        adUnits="lb1,lb2,imu1,imu2,catfish,prestitial"
        pageTitle={data.name}
        currentLocation={location}
      />

      <LatestStoryLayout
        data={{
          name: configLatestStory.customTitle || data.name,
          nameZH: data.nameZH,
          anchorBlock: topStory
            ? mapTeaserDataToAnchor(topStory, GA4_REF_CODE_ANCHORBLOCK_B)
            : undefined,
          latestArticle: latestArticle.map((teaser) =>
            mapTeaserDataToLatestProps(teaser, GA4_REF_CODE_TITLE_STACKED),
          ),
          mainContentBlock: data.listedStories.map((teaser) =>
            mapTeaserDataToLatestProps(teaser, GA4_REF_CODE_CONTENTBLOCK_L),
          ),
          showVerticalLine: false,
        }}
      />
    </PubBaseLayout>
  );
}
