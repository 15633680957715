import { TeaserDataApiResponse } from "@app/types/ServerCustomContext";
import { GA4_REF_CODE_RELATED } from "@app/utils/ga/ref-code";
import { buildUrlWithGARefCode } from "@app/utils/ga/tracking";
import { configSingleStory } from "@pub/config";
import placeholders from "@pub/placeholders";
import { RelatedArticle } from "@sphtech/dmg-design-system";
import useSWR from "swr";

type RelatedArticleInlineProps = {
  ids: string[];
  htmlAnchor?: string;
  asLatestTeaser?: boolean;
};
function RelatedArticleInline({
  ids,
  htmlAnchor,
  asLatestTeaser,
}: RelatedArticleInlineProps) {
  const { data } = useSWR<TeaserDataApiResponse>(
    `/_plat/api/teaser/by_id?ids=${ids.join(",")}`,
  );

  if (!data) return null;

  const stories = data.results
    ?.map((ele) => {
      const { id, title, path, image, description, sections } = ele;
      return {
        id: id || "",
        title: title,
        url: buildUrlWithGARefCode(path, GA4_REF_CODE_RELATED),
        image: image?.crops.landscape,
        description: description,
        sections: sections,
      };
    })
    //sort based on the order of ids
    .sort((a, b) => {
      return ids.indexOf(a.id) - ids.indexOf(b.id);
    });
  if (!stories?.length) {
    return null;
  }
  return (
    <RelatedArticle
      stories={stories}
      displayImage={true}
      headerText={configSingleStory.relatedArticle?.headerText}
      hasRelatedPrefix={configSingleStory.relatedArticle?.hasRelatedPrefix}
      imageErrorSrc={placeholders.landscape.src}
      htmlAnchor={htmlAnchor}
      asLatestTeaser={asLatestTeaser}
    />
  );
}

export default RelatedArticleInline;
