import { configHome, configTeaser } from "@pub/config";
import placeholders from "@pub/placeholders";
import {
  AnchorBlock,
  ContentBlock,
  LatestArticle,
  SectionContext,
} from "@sphtech/dmg-design-system";
import cx from "classnames";

import styles from "../homelayout.module.css";
import { HomeContentBlocks } from "./utils";

type Props = {
  contentBlockI: HomeContentBlocks;
};

/**
 * Description: Left side AnchorBlock; Right side 2-col masonry layout
 */
const HomeContentBlockIA = ({ contentBlockI }: Props) => {
  if (!contentBlockI.items[0]) return null;

  return (
    <ContentBlock
      inlineGutter={false}
      heading={contentBlockI.title}
      headingZH={contentBlockI.titleZH}
      cta={{
        name: "VIEW ALL",
        url: contentBlockI.url,
        icon: configHome.viewMore?.icon,
      }}
      withDivider={configHome.headingDivider}
      isHeadingClickable={configHome.isHeadingClickable}
    >
      <SectionContext.Provider value={{ uniqueName: undefined }}>
        <div className={styles.flex}>
          <div className={cx(styles.latestArticleContainer)}>
            <AnchorBlock
              {...contentBlockI.items[0]}
              loading="lazy"
              placeholder={placeholders.landscape}
              mobileCrop={configTeaser.anchorBlock?.mobileCrop}
              CustomHeadingElement="h3"
            />
          </div>

          <div className={styles.masonryLayout}>
            {contentBlockI.items.slice(1).map((item, index) => {
              return (
                <div key={index} className={styles.masonryItem}>
                  <LatestArticle
                    {...item}
                    mode="original"
                    mobileMode="landscape"
                    placeholders={placeholders}
                    displayPublishDate={configTeaser.publishDate}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </SectionContext.Provider>
    </ContentBlock>
  );
};

export default HomeContentBlockIA;
