import React, { ReactNode } from "react";
import styles from "./SectionHeading.module.css";
import Divider from "../../Element/Divider/divider";
import * as Typography from "../../Element/Typography";
import { useCustomClassContext } from "../../../hooks";
import cx from "classnames";

const { Heading } = Typography;
const { SectionTitle, SubSectionTitle, SubHeading4 } = Heading;

export type SectionHeadingProps = {
  title: string;
  description?: string;
  arrows?: React.ReactNode;
  headingUrl?: string | undefined;
  withDivider?: boolean;
};

type Element = "title" | "subheading";

function createHeading(Element: Element) {
  const Title = Element === "title" ? SectionTitle : SubSectionTitle;
  return ({
    title,
    description,
    arrows,
    headingUrl,
    withDivider = true,
  }: SectionHeadingProps): ReactNode => {
    const customClass = useCustomClassContext();
    const elements = (
      <div
        className={cx(
          styles.sectionHeadingContainer,
          customClass?.sectionHeading?.container,
        )}
      >
        <Title>{headingUrl ? <a href={headingUrl}>{title}</a> : title}</Title>
        {description && (
          <div className={styles.sectionHeadingDescription}>
            <SubHeading4>{description}</SubHeading4>
          </div>
        )}
        {arrows && <div className={styles.arrowIcons}>{arrows}</div>}
      </div>
    );
    if (withDivider) {
      // extra div wrapper needed to avoid row gap in current usage secenarios
      return (
        <div>
          {elements}
          {withDivider && <Divider />}
        </div>
      );
    }
    return elements;
  };
}

export const SectionHeading = createHeading("title");

export const SectionSubHeading = createHeading("subheading");
