import { TeaserData } from "@app/types/ServerCustomContext";
import { configCategory, configLatestStory, configTeaser } from "@pub/config";
import placeholders from "@pub/placeholders";
import {
  Ads,
  LatestArticle,
  useCustomClassContext,
} from "@sphtech/dmg-design-system";
import cx from "classnames";

import categoryStyle from "./category.module.css";
import styles from "./subanchor.module.css";

type SubAnchorProps = {
  articleList: TeaserData[] | undefined;
};

const SubAnchor = ({ articleList }: SubAnchorProps) => {
  const customClass = useCustomClassContext();
  const landscapeIndexes =
    configCategory.latestStory?.landscapeIndexes ||
    configLatestStory.landscapeIndexes;

  if (articleList && articleList.length > 0) {
    return articleList.map((teaser, index: number) => (
      <div
        key={index}
        className={cx(
          styles.subAnchor,
          landscapeIndexes.includes(index) ? styles.landscape : styles.portrait,
          customClass?.subAnchor?.item,
        )}
      >
        <LatestArticle
          key={index}
          {...teaser}
          mode={landscapeIndexes.includes(index) ? "landscape" : "portrait"}
          portraitImageWidth={configLatestStory.portraitImageWidth}
          landscapeImageWidth={configLatestStory.landscapeImageWidth}
          placeholders={placeholders}
          displayPublishDate={configTeaser.publishDate}
          mobileMode={configTeaser.mobileMode}
          CustomHeadingElement="h2"
          dateAfter={configTeaser.dateAfter}
        />

        {index === configCategory.subAnchorAdPositioning && (
          <div className={categoryStyle.mobileAdvertisement}>
            <Ads.AdsSideBarMobile />
          </div>
        )}
      </div>
    ));
  }
};

export default SubAnchor;
