import { configContentHub } from "@pub/config";
import placeholders from "@pub/placeholders";
import { VideoGalleryLandscape } from "@sphtech/dmg-design-system";
import { ContentHubData } from "@src/app/types/ServerCustomContext";
import cx from "classnames";
import { ReactNode } from "react";

import styles from "../contenthub.module.css";

type Props = {
  data?: Omit<ContentHubData, "metaData">;
};

export default function ContentHubVideoGalleryLandscape({
  data,
}: Props): ReactNode {
  const ContentVideoBlock = data?.videoPlaylist;
  if (!ContentVideoBlock?.videoTeaser) return null;

  return (
    <div
      className={cx(
        styles.backgroundFill,
        styles.videoGalleryLandscapeContainer,
        styles.videoGalleryColorOverride,
      )}
    >
      <div className={styles.bodyContainer}>
        <VideoGalleryLandscape
          title={
            (configContentHub.videoGalleryLandscape?.heading?.title &&
              ContentVideoBlock.heading) ||
            ""
          }
          items={ContentVideoBlock.videoTeaser.map((item) => {
            const crop = item.image?.crops.landscape || placeholders.landscape;
            return {
              ...crop,
              videoUrl: item.embedUri,
              sections: item.sections,
              caption: item.title,
              captionUrl: item.path,
              description: item.description,
            };
          })}
          heading={{
            withDivider:
              configContentHub.videoGalleryLandscape?.heading?.withDivider,
            hasArrows:
              configContentHub.videoGalleryLandscape?.heading?.hasArrows,
          }}
          strip={
            configContentHub.videoGalleryLandscape?.strip && {
              dividerVariant:
                configContentHub.videoGalleryLandscape.strip.dividerVariant,
              arrowSize: 30,
            }
          }
          hasPagination={configContentHub.videoGalleryLandscape?.hasPagination}
          hasStripLabel={true}
          arrowSize={29}
        />
      </div>
    </div>
  );
}
