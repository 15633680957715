import cx from "classnames";
import Divider from "../../Element/Divider/divider";
import ResponsiveImage from "../../Element/ResponsiveImage";
import * as Typography from "../../Element/Typography";
import { BaseTeaserData } from "../../Utility/dataTypes";
import { placeholderCrops } from "../../Utility/placeholder";
import styles from "./navbardropdown.module.css";
import { NavigationProps } from "../../Module/NavbarGrid/types";
import { useCustomClassContext } from "../../../hooks";

const { Heading, Body } = Typography;

export type DropdownListItem = {
  title?: string;
  path?: string;
  image?: BaseTeaserData["image"];
  description?: string;
};

export type DropdownItemProps = {
  item: DropdownListItem;
  optionsDropdown?: NavigationProps["navbarDropdownOptions"];
};

type NavbarDropdownProps = {
  children: React.ReactNode;
  dropdownList?: DropdownListItem[];
  // Used for Storybook snapshot
  alwaysShowDropdown?: boolean;
  optionsDropdown?: NavigationProps["navbarDropdownOptions"];
};

const DropdownItem = ({ item, optionsDropdown }: DropdownItemProps) => {
  const placeholder = placeholderCrops.landscape;
  const landscapeCrop = item.image?.crops.landscape;
  const originalCrop = item.image?.crops.original;

  const imageCrop = landscapeCrop || originalCrop || placeholder;

  return (
    <>
      <a href={item.path} className={styles.dropdownItem}>
        {optionsDropdown?.displayImage !== false && imageCrop && (
          <div className={styles.imageContainer}>
            <ResponsiveImage {...imageCrop} />
          </div>
        )}

        <div className={styles.summaryContainer}>
          <Heading.SubHeading3>{item.title}</Heading.SubHeading3>
          {item.description && <Body>{item.description}</Body>}
        </div>
      </a>

      <div className={styles.dividerContainer}>
        <Divider />
      </div>
    </>
  );
};

const NavbarDropdown = ({
  children,
  dropdownList,
  // Used for Storybook snapshot
  alwaysShowDropdown = false,
  optionsDropdown = {
    displayImage: true,
  },
}: NavbarDropdownProps): React.ReactNode => {
  const customClass = useCustomClassContext();

  return (
    dropdownList &&
    dropdownList.length > 0 && (
      <div className={cx(styles.dropdown, customClass?.navbar?.dropDown)}>
        <div className={styles.dropdownName}>{children}</div>
        <section
          className={cx(
            styles.dropdownSection,
            !alwaysShowDropdown && styles.hidden,
          )}
        >
          {dropdownList?.map((item) => (
            <DropdownItem
              key={item.title}
              item={item}
              optionsDropdown={optionsDropdown}
            />
          ))}
        </section>
      </div>
    )
  );
};

export default NavbarDropdown;
