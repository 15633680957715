import {
  ProcessedElements,
  ResolvedCUEElement,
  ResovledAuthorProfileElement,
} from "@app/layouts/Article/elements";
import Storyline from "@app/layouts/Article/Storyline";
import { StaticContent } from "@app/types/ServerCustomContext";
import AuthorBlock from "@components/Modules/AuthorBlock/AuthorBlock";
import { configAboutUs, configAuthorProfile, configSite } from "@pub/config";
import {
  ResponsiveImage,
  Typography,
  useCustomClassContext,
} from "@sphtech/dmg-design-system";
import cx from "classnames";
import { ReactNode } from "react";

import styles from "./aboutus.module.css";

export interface AboutUsLayoutProps {
  headline: string;
  headlineZH?: string;
  standfirst?: ReactNode;
  coverImage?: ProcessedElements["coverImage"];
  bodyElements: ResolvedCUEElement[];
  path: string;
  displaySetting: StaticContent["displaySetting"];
}

export default function AboutUsLayout({
  headline,
  headlineZH,
  standfirst,
  coverImage,
  bodyElements,
  path,
  displaySetting,
}: AboutUsLayoutProps): ReactNode {
  const customClass = useCustomClassContext();
  const { Heading } = Typography;
  const showHeadline = displaySetting?.displayHeadline !== "x-mag-none";

  const displayHeadline =
    showHeadline &&
    (headlineZH ? (
      <Heading.SectionTitle nameZH={headlineZH}>
        {headline}
      </Heading.SectionTitle>
    ) : (
      headline
    ));

  const profileElements = bodyElements.filter(
    (e): e is ResovledAuthorProfileElement => e.type === "relation_profile",
  );
  const splitIndex = profileElements[0]
    ? bodyElements.indexOf(profileElements[0])
    : bodyElements.length;
  const profileHeadline =
    profileElements.length && bodyElements[splitIndex - 1]?.type === "sub_head"
      ? bodyElements[splitIndex - 1]
      : undefined;

  const remainingElements = bodyElements.filter(
    (e) => e.type !== "relation_profile",
  );
  const elementsBefore = remainingElements.slice(
    0,
    splitIndex - (profileHeadline ? 1 : 0),
  );
  const elementsAfter = remainingElements.slice(splitIndex);

  const originalCrop = coverImage?.crops.original;
  const headlineBelowCoverImage = configSite.aboutUs?.headlineBelow;

  return (
    <>
      {originalCrop && (
        <div className={styles.headingBackgroundContainer}>
          <ResponsiveImage {...originalCrop} displayWidth={2000} />
          {!headlineBelowCoverImage && (
            <div
              className={cx(
                styles.headingContainer,
                customClass?.aboutUs?.heading,
              )}
            >
              <label>{displayHeadline}</label>
            </div>
          )}
          {configAboutUs.standfirst && standfirst && (
            <div
              className={cx(
                styles.standfirstContainer,
                customClass?.aboutUs?.standfirst,
              )}
            >
              <Heading.SubHeading>{standfirst}</Heading.SubHeading>
            </div>
          )}
        </div>
      )}

      {headlineBelowCoverImage && (
        <div className={styles.headingTextContainer}>
          <div className={styles.headingContainer}>{displayHeadline}</div>
        </div>
      )}
      <div className={cx(styles.bodyContainer, styles.gutter)}>
        <div className={cx(styles.storylineContainer, styles.intro)}>
          <Storyline
            bodyMaxWidth={800}
            path={path}
            excludeAds={true}
            elements={elementsBefore}
          />
        </div>
      </div>
      {!!profileElements.length && (
        <div className={styles.authorBlockContainer}>
          <AuthorBlock
            data={profileElements}
            profileHeadlineElement={profileHeadline}
            readMoreText={configAuthorProfile.readMoreText}
          />
        </div>
      )}
      {!!elementsAfter.length && (
        <div className={cx(styles.bodyContainer, styles.gutter)}>
          <div className={cx(styles.storylineContainer, styles.contactUs)}>
            <Storyline
              bodyMaxWidth={800}
              path={path}
              excludeAds={true}
              elements={elementsAfter}
            />
          </div>
        </div>
      )}
    </>
  );
}
