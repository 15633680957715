import React from "react";
import ResponsiveImage from "../../Element/ResponsiveImage";
import { BaseTeaserData, ImageCrop } from "../../Utility/dataTypes";
import style from "./index.module.css";
import LatestArticle from "../LatestArticle/LatestArticle";

export type RelatedArticleData = {
  title: string;
  url: string;
  image?: ImageCrop;
  sections?: BaseTeaserData["sections"];
  description?: string;
};

export type RelatedArticleProps = {
  /**
   * Related stories to display. Expected values are array of objects
   * such as [{id: 1, title: "I am title", url: "https://www.mywebsite.com", imgUrl: "https://placehold.co/600x400"},
   * {id: 2, title: "I am title 2", url: "https://www.mywebsite.com", imgUrl: "https://placehold.co/600x400"}
   * ]
   */
  stories: RelatedArticleData[];
  /**
   * This is an option to show or hide the image thumbnail for the entire component.
   * Allowing only to show the text. It superseded the logic of whether the related story
   * has a featured image or no. Her World as of the moment is requesting this option to entirely
   * hide the image leaving only the text to appear.
   */
  displayImage: boolean;
  imageErrorSrc?: string;
  headerText?: string;
  hasRelatedPrefix?: boolean;
  htmlAnchor?: string;
  asLatestTeaser?: boolean;
};

/**
 * This is our related article component
 */
const RelatedArticle = (props: RelatedArticleProps): React.ReactElement => {
  const {
    stories,
    displayImage = true,
    headerText,
    hasRelatedPrefix = true,
    htmlAnchor,
    asLatestTeaser = false,
  } = props;

  const RelatedArticleItem = ({ url, title, image }: RelatedArticleData) => {
    const imageContainer = displayImage && image && (
      <span className={style.imageContainer}>
        <ResponsiveImage
          {...image}
          displayWidth={225}
          errorSrc={props.imageErrorSrc}
        />
      </span>
    );
    return (
      <a href={url} className={style.itemContainer}>
        {imageContainer}
        <span className={style.textContainer}>
          {hasRelatedPrefix && <b>Related: </b>}
          {title}
        </span>
      </a>
    );
  };

  const AsTeaser = ({ url, title, image, sections }: RelatedArticleData) => {
    if (!sections) return null;
    return (
      <LatestArticle
        title={title}
        mode="landscape"
        sections={sections}
        path={url}
        image={{
          crops: {
            landscape: image,
          },
          alt: title,
        }}
      />
    );
  };

  return (
    <div className={style.relatedArticle} id={htmlAnchor}>
      {headerText && <p className={style.headerText}>{headerText}</p>}
      {stories.map((item, index) => {
        return asLatestTeaser ? (
          <AsTeaser key={index} {...item} />
        ) : (
          <RelatedArticleItem key={index} {...item} />
        );
      })}
    </div>
  );
};

export default RelatedArticle;
