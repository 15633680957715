/**
 * Company: SPHMedia
 * Description: This is the component responsible for infinite scrolling of content-hub stories
 */

import { configTrackingCode, CustomListingLayoutProps } from "@pub/config";
import { Loader } from "@sphtech/dmg-design-system";
import { useInView } from "@src/app/components/hooks/hook";
import { TeaserDataApiResponse } from "@src/app/types/ServerCustomContext";
import { mapTeaserDataToLatestProps } from "@src/app/utils/mapTeaserDataToProps";
import { LOAD_MORE_TEXT } from "@src/pubs/common/config";
import { ReactElement, ReactNode, useEffect } from "react";
import useSWRInfinite from "swr/infinite";

import ContentHubStoryListingLayout from "./ContentHubStoryListingLayout";

type Props = {
  data: {
    tagSlugs: string;
    limit: number;
    ignoreIds: string[];
    lb1?: ReactNode;
  };
  CustomListingLayout?: CustomListingLayoutProps;
};

/**
 * ContentHubStoryListing
 * Description: This is the component responsible for infinite scrolling of content-hub stories
 * @param props - The props required by the component
 * @returns The rendered content-hub stories
 */
export default function ContentHubStoryListing({
  data,
  CustomListingLayout,
}: Props): ReactElement {
  const { ref, inView } = useInView({ threshold: 0.1 });
  const limit = data.limit;

  const getKey = (pageIndex: number) => {
    return `/_plat/api/teaser/latest?${[
      `tags=${encodeURIComponent(data.tagSlugs)}`,
      `ignoreIds=${encodeURIComponent(data.ignoreIds.join(","))}`,
      `from=${pageIndex * limit}`,
      `size=${limit}`,
    ].join("&")}`;
  };

  const {
    data: moreData,
    isLoading,
    size,
    setSize,
  } = useSWRInfinite<TeaserDataApiResponse>(getKey, {
    revalidateFirstPage: false,
    initialSize: 0,
  });

  const sizeLength = size - 1;
  const isLoadingMore =
    isLoading ||
    (size > 0 && moreData && typeof moreData[sizeLength] === "undefined");

  const hasMoreData = size === 0 || !!moreData?.[size - 1]?.results?.length;

  useEffect(() => {
    if (!inView || !hasMoreData) return;
    void setSize((prev) => {
      window.SPHMGTM.cmd.push(`pagination=${prev + 1}`);
      return prev + 1;
    });
  }, [inView, setSize, hasMoreData]);

  return (
    <>
      {moreData &&
        moreData.length > 0 &&
        moreData.map((el, index) => {
          if (!el?.results?.length) {
            return;
          }

          const elem = el.results.map((teaser) =>
            mapTeaserDataToLatestProps(
              teaser,
              configTrackingCode.contenthub?.listing,
            ),
          );

          return (
            <>
              {data.lb1}
              {CustomListingLayout ? (
                <CustomListingLayout
                  key={index}
                  isListing={true}
                  data={{ listedStories: elem }}
                />
              ) : (
                <ContentHubStoryListingLayout
                  key={index}
                  data={{
                    listedStories: elem,
                  }}
                />
              )}
            </>
          );
        })}
      <div ref={ref}>{isLoadingMore && <Loader label={LOAD_MORE_TEXT} />}</div>
    </>
  );
}
