import { TeaserData } from "@app/types/ServerCustomContext";
import {
  configContentHub,
  configTeaser,
  CustomListingLayoutProps,
} from "@pub/config";
import placeholders from "@pub/placeholders";
import { LatestArticle } from "@sphtech/dmg-design-system";
import cx from "classnames";
import { ReactElement } from "react";

import styles from "./contenthub.module.css";

type Props = {
  data: {
    listedStories?: TeaserData[];
  };
  displayPlayIcon?: boolean;
  CustomListingLayout?: CustomListingLayoutProps;
};

/**
 * ContentHubStoryLayout
 * Description: Render content-hub stories
 * @param props - The props required by the component
 * @returns The rendered React element
 */
export default function ContentHubStoryListingLayout({
  data,
  displayPlayIcon,
  CustomListingLayout,
}: Props): ReactElement {
  return (
    <>
      {CustomListingLayout ? (
        <CustomListingLayout
          isListing={true}
          data={{ listedStories: data.listedStories }}
        />
      ) : (
        data.listedStories && (
          <div className={cx(styles.contentBlockContainer)}>
            <div className={styles.contentBlockLayout}>
              {data.listedStories.map((teaser, index: number) => (
                <div key={index} className={styles.contentBlockItem}>
                  <LatestArticle
                    {...teaser}
                    mode={
                      configContentHub.configListingStories?.mode || "portrait"
                    }
                    placeholders={placeholders}
                    displayPublishDate={configTeaser.publishDate}
                    displayPlayIcon={displayPlayIcon}
                    mobileMode={
                      configContentHub.configListingStories?.mobileMode
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        )
      )}
    </>
  );
}
