import { Helmet } from "react-helmet-async";
import newRelicAgent from "./scripts/nr-loader-spa-1.283.2.min.js?url";

const id = import.meta.env.VITE_NEW_RELIC_ID;
const key = import.meta.env.VITE_NEW_RELIC_KEY;

const isConfigured = !!(id && key);
if (!isConfigured) {
  console.warn("Skipping NewRelic import.meta.env", {
    VITE_NEW_RELIC_ID: id,
    VITE_NEW_RELIC_KEY: key,
  });
}

const NewRelic = isConfigured
  ? () => {
      return (
        <Helmet>
          <script>
            {[
              "window.NREUM||(NREUM={})",
              `NREUM.init=${JSON.stringify({
                jserrors: { enabled: true },
                logging: { enabled: false },
                metrics: { enabled: true },
                page_view_timing: { enabled: true },
                spa: { enabled: false },
                soft_navigations: { enabled: false },
                page_action: { enabled: false },
                page_view_event: { enabled: false },
                user_actions: { enabled: false },
                generic_events: { enabled: false },
                session_replay: { enabled: false },
                session_trace: { enabled: false },
                ajax: {
                  enabled: false,
                  // deny_list: ["bam.nr-data.net"]
                },
                privacy: { cookies_enabled: true },
              })};`,
              `NREUM.loader_config=${JSON.stringify({
                accountID: "225023",
                trustKey: "225023",
                agentID: id,
                licenseKey: key,
                applicationID: id,
              })}`,
              `NREUM.info=${JSON.stringify({
                beacon: "bam.nr-data.net",
                errorBeacon: "bam.nr-data.net",
                licenseKey: key,
                applicationID: id,
                sa: 1,
              })}`,
            ].join(";")}
          </script>
          <script type="text/javascript" src={newRelicAgent} async />
        </Helmet>
      );
    }
  : () => null;

export default NewRelic;
