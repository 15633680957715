import React, { ReactElement } from "react";
import styles from "./label.module.css";
import cx from "classnames";
import { useCustomClassContext } from "../../../../hooks";

export type Element = "div" | "span"; // Modify this as needed
export type LabelProps = {
  Element?: Element;
  children?: React.ReactNode;
};

function Label({ Element = "div", children }: LabelProps): ReactElement {
  const customClass = useCustomClassContext();
  return (
    <Element className={cx(styles.label, customClass?.label)}>
      {children}
    </Element>
  );
}

export default Label;
