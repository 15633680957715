import { configHome, configTeaser, DataSource } from "@pub/config";
import placeholders from "@pub/placeholders";
import {
  ContentBlock,
  LatestArticle,
  SectionContext,
} from "@sphtech/dmg-design-system";
import cx from "classnames";
import { ReactNode } from "react";

import styles from "../homelayout.module.css";
import { extractHomeContentData, HomeLayoutProps } from "./utils";

type Props = {
  data: HomeLayoutProps["data"];
  dataSource: DataSource;
  alternateBg?: boolean;
  trendingBlock?: boolean;
};

// ContentBlock 3 items | Trending | Content Block D
export default function HomeContentBlockD({
  data,
  dataSource,
  alternateBg,
  trendingBlock,
}: Props): ReactNode {
  const ContentBlockThreeItems = extractHomeContentData(data, dataSource);

  return (
    !!ContentBlockThreeItems?.items.length && (
      <div
        className={cx(
          alternateBg && styles.backgroundFill,
          styles.trendingContainer,
          trendingBlock && styles.trendingAltStyle,
        )}
      >
        <div className={cx(styles.bodyContainer, styles.gutter)}>
          <ContentBlock
            heading={ContentBlockThreeItems.title}
            headingZH={ContentBlockThreeItems.titleZH}
            cta={{
              name: ContentBlockThreeItems.cta
                ? configHome.contentBlockF?.viewMore?.text || "VIEW ALL"
                : undefined,
              url: ContentBlockThreeItems.url,
              icon: configHome.viewMore?.icon,
            }}
            withDivider={configHome.headingDivider}
            isHeadingClickable={configHome.isHeadingClickable}
          >
            <SectionContext.Provider
              value={{
                uniqueName: ContentBlockThreeItems.uniqSectionName,
              }}
            >
              <div className={styles.contentBlockContainer}>
                {ContentBlockThreeItems.items.map((contentBlockItem, index) => {
                  return (
                    <div className={styles.content} key={index}>
                      <LatestArticle
                        {...contentBlockItem}
                        mode={
                          configHome.contentBlockD?.squareIndexes?.includes(
                            index,
                          )
                            ? "square"
                            : configHome.contentBlockD?.crop || "portrait"
                        }
                        mobileMode={configHome.contentBlockD?.mobileMode}
                        placeholders={placeholders}
                        descriptionInTitle={
                          configHome.contentBlockD?.descriptionInTitle
                        }
                        displayPublishDate={configTeaser.publishDate}
                      />
                    </div>
                  );
                })}
              </div>
            </SectionContext.Provider>
          </ContentBlock>
        </div>
      </div>
    )
  );
}
