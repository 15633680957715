import { configHome } from "@pub/config";
import { Fragment, ReactElement } from "react";

import { elementsWithDataSource, elementsWithoutDataSource } from "./Elements";
import { HomeLayoutProps } from "./Elements/utils";

export default function HomeLayout({ data }: HomeLayoutProps): ReactElement {
  return (
    <Fragment>
      {configHome.layoutGrid.elements.map((element, k) => {
        const hasDataSource = "dataSource" in element;
        if (hasDataSource) {
          const ElementComponent =
            "Component" in element
              ? element.Component
              : elementsWithDataSource[element.name];
          return (
            <ElementComponent
              key={k}
              data={data}
              dataSource={element.dataSource}
              alternateBg={element.alternateBg}
              trendingBlock={element.trendingBlock}
            />
          );
        }
        const ElementComponent = elementsWithoutDataSource[element.name];
        return <ElementComponent key={k} data={data} />;
      })}
    </Fragment>
  );
}
