import { configHome, configTeaser } from "@pub/config";
import placeholders from "@pub/placeholders";
import { AnchorBlock, useCustomClassContext } from "@sphtech/dmg-design-system";
import cx from "classnames";
import { ReactNode } from "react";

import styles from "../homelayout.module.css";
import { HomeLayoutProps } from "./utils";

type Props = {
  data: HomeLayoutProps["data"];
};

export default function HomeAnchorBlock({ data }: Props): ReactNode {
  const customClass = useCustomClassContext();
  if (!data.AnchorBlockProps) {
    return;
  }
  return (
    <div
      className={cx(
        styles.anchorBlockContainer,
        styles.bodyContainer,
        customClass?.homeAnchorBlock,
      )}
    >
      <AnchorBlock
        {...data.AnchorBlockProps}
        placeholder={placeholders.landscape}
        loading="eager"
        CustomHeadingElement="h1"
        hideBreadCrumb={configHome.anchorBlock?.hideBreadCrumb}
        hideVerticalLine={configHome.anchorBlock?.hideVerticalLine}
        mobileCrop={configTeaser.anchorBlock?.mobileCrop}
        displayPublishDate={configHome.teaser?.displayPublishDate}
        displayType={configHome.anchorBlock?.displayType}
      />
    </div>
  );
}
