import "@src/app/types/react.d";

import { PUBLIC_PREVIEW_TEXT, TYPE_FEATURE_STORY } from "@app/constants";
import { StoryData } from "@app/types/ServerCustomContext";
import { AnalyticsArticle } from "@app/utils/AnalyticsSettings";
import { getEmbedUri } from "@app/utils/embed";
import { isSponsoredStory } from "@app/utils/sponsored";
import { useInView } from "@components/hooks/hook";
import AppExploreMore from "@components/Modules/AppExploreMore/AppExploreMore";
import { getAuthors } from "@components/Modules/MetaData/mappers";
import OutbrainScript from "@components/Modules/Outbrain/OutbrainScript";
import { configExploreMore, configSingleStory, configSite } from "@pub/config";
import placeholders from "@pub/placeholders";
import {
  Ads,
  AiSummary,
  ArticleMastHead,
  BreadCrumbs,
  Button,
  Divider,
  PageShare,
  ResponsiveImage,
  SponsorshipBox,
  Typography,
  useCustomClassContext,
  UserSubmissionBox,
} from "@sphtech/dmg-design-system";
import cx from "classnames";
import React, { useEffect, useState } from "react";

import { Deals } from "./Deals";
import styles from "./SinglePostLayout.module.css";
import Storyline from "./Storyline";
import { Annotation } from "./StorylineElements/Annotation/Annotation";
import Sponsorship from "./StorylineElements/Sponsorship";
import TagContainer from "./TagContainer";

const { DateTime, DateLine, Heading } = Typography;

export type SinglePostProps = {
  storyData: StoryData;
  userSubmissionBox?: UserSubmissionBox;
  hideContent?: boolean;
  infiniteScrolling?: boolean;
  onInView: (storyData: StoryData, inView: boolean) => void;
};

function SinglePostLayout({
  storyData,
  userSubmissionBox,
  hideContent = false,
  infiniteScrolling = false,
  onInView,
}: SinglePostProps) {
  const { configShareArticle } = configSingleStory;
  const customClass = useCustomClassContext();
  const [hide, setHide] = useState(hideContent);

  const { ref, inView } = useInView({
    threshold: 0,
  });
  useEffect(() => {
    onInView(storyData, inView);
  }, [inView, storyData, onInView]);
  const { ref: exploreMoreRef, inView: exploreMoreInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const {
    id,
    tags,
    typeName,
    sections,
    processedElements,
    sponsorship,
    displaySetting,
    aiSummary,
  } = storyData;

  const {
    titleElement,
    standfirstElement,
    bodyElements,
    coverImage,
    videoElement,
  } = processedElements;

  const isFeatureStory = typeName === TYPE_FEATURE_STORY;

  const mastheadProps = (configSingleStory.featureStory?.imageInsideMasthead ||
    !isFeatureStory) && {
    video: videoElement && {
      url: getEmbedUri(videoElement),
    },
    image: coverImage?.crops.original,
  };

  const displayAuthors = getAuthors(storyData);

  const bylineAndDate = (
    <DateLine>
      {displayAuthors.length ? (
        <>
          {configSingleStory.byline?.authors?.byWord ?? "By\u00a0"}
          {displayAuthors
            .map<React.ReactNode>((author, index) =>
              author.href ? (
                <a href={author.href} key={index} className={styles.authorLink}>
                  {author.text}
                </a>
              ) : (
                author.text
              ),
            )
            .reduce((prev, curr) => [
              prev,
              configSingleStory.byline?.authors?.separator ?? " and ",
              curr,
            ])}
          {configSingleStory.byline?.separator ?? "\u00a0-\u00a0"}
        </>
      ) : null}
      {storyData.publishDate && (
        <>
          {configSingleStory.prefixForDateLine}
          <DateTime
            date={new Date(storyData.publishDate)}
            format="dd mname yyyy"
          />
        </>
      )}
    </DateLine>
  );

  // Special case for cover caption, based on https://sph.atlassian.net/browse/DMG-3574
  const coverImageCaption =
    coverImage?.title || coverImage?.credit || coverImage?.caption;

  const coverImageCrop =
    coverImage?.crops.square_30_26 || coverImage?.crops.original;

  const bodyMaxWidth = isFeatureStory
    ? (configSingleStory.featureStory?.bodyMaxWidth ?? 1000)
    : (configSingleStory.bodyMaxWidth ?? 655);

  const renderAfterDescription = () => {
    if (configSingleStory.byline?.moveToShare) return null;
    if (configSingleStory.byline?.moveShareToDescription) {
      return (
        <PageShare
          label=""
          url={`${configSite.address}${storyData.path}`}
          title={titleElement?.value}
          size={configSite.pageShare?.size}
          media={configSite.pageShare?.media}
          fill={configSite.pageShare?.fill}
        />
      );
    }
    return bylineAndDate;
  };

  const featuredImage = (
    <figure className={styles.featureStoryImageCover}>
      {coverImageCrop && (
        <ResponsiveImage
          {...coverImageCrop}
          displayWidth={1000}
          mobileUp={
            coverImage.crops.original && {
              ...coverImage.crops.original,
              displayWidth: 1920,
            }
          }
        />
      )}
      {!configSingleStory.featureStory?.skipCoverCaption &&
        coverImageCaption && (
          <figcaption
            className={cx(
              styles.bodyContainer,
              styles.featureStoryImageCoverCaption,
            )}
          >
            <Typography.Disclaimer>{coverImageCaption}</Typography.Disclaimer>
          </figcaption>
        )}
    </figure>
  );
  return (
    <article className={styles.articleContainer}>
      <div ref={ref} className={styles.articleTracker}></div>
      {!configSingleStory.featureStory?.imageInsideMasthead &&
        isFeatureStory &&
        featuredImage}
      <div
        style={{
          "--custom-body-max-width": `${bodyMaxWidth}px`,
        }}
        className={cx(
          styles.bodyContainer,
          isFeatureStory && styles.featureStory,
        )}
      >
        {infiniteScrolling && !isFeatureStory && <Ads.AdsMidContent />}

        {displaySetting?.publicPreview && (
          <div className={cx(styles.previewMode, styles.gutter)}>
            {PUBLIC_PREVIEW_TEXT}
          </div>
        )}

        <div className={styles.articlmasthead}>
          <ArticleMastHead
            {...mastheadProps}
            articleHeading={
              titleElement && <Annotation element={titleElement} />
            }
            divider={configSingleStory.topDivider}
            category={
              <BreadCrumbs
                sections={sections}
                options={{ depth: configSite.sectionLevel3 ? 3 : 2 }}
              />
            }
            excerpt={
              standfirstElement?.value && (
                <Annotation element={standfirstElement} />
              )
            }
            isFeatured={
              !configSingleStory.featureStory?.imageInsideMasthead &&
              isFeatureStory
            }
            placeholder={placeholders.landscape}
            afterDescription={renderAfterDescription()}
            coverImageCaption={coverImageCaption}
          />
        </div>
        {!hide && (
          <div className={styles.sidebarContainer}>
            <div className={cx(styles.body, styles.gutter)}>
              {configSingleStory.displayTags?.displayTopTags && (
                <TagContainer tags={tags} />
              )}
              <div
                className={cx(
                  styles.pageShareSingleContainer,
                  styles.pageShareSingleContainerTop,
                )}
              >
                {configSingleStory.byline?.moveShareToDescription ? (
                  <div className={styles.standaloneByline}>
                    <Heading.SubHeading4>{bylineAndDate}</Heading.SubHeading4>
                  </div>
                ) : (
                  <PageShare
                    label={
                      configSingleStory.byline?.moveToShare
                        ? bylineAndDate
                        : configShareArticle.label
                    }
                    url={`${configSite.address}${storyData.path}`}
                    title={titleElement?.value}
                    size={configSite.pageShare?.size}
                    media={configSite.pageShare?.media}
                    fill={configSite.pageShare?.fill}
                  />
                )}
              </div>

              {configSingleStory.aiSummary?.enabled && aiSummary && (
                <AiSummary
                  data={{
                    id: storyData.id,
                    title: titleElement?.value || "",
                    path: storyData.path,
                    items: aiSummary.value,
                  }}
                  gaEventTracker={() => {
                    window.SPHMGTM.cmd.push(`summary=${storyData.path}`);
                    window.SPHMGAM.cmd.push("aisummary");
                  }}
                  submitStarRating={(rating) => {
                    window.SPHMGTM.cmd.push(
                      `send=${JSON.stringify({
                        type: "event",
                        category: "AI-Summary",
                        action: "click",
                        label: `rating=${rating}`,
                        other: {
                          path: storyData.path,
                        },
                      })}`,
                    );
                  }}
                />
              )}

              <Storyline
                elements={bodyElements}
                path={storyData.path}
                bodyMaxWidth={bodyMaxWidth}
                excludeAds={isSponsoredStory(sponsorship, typeName)}
              />

              <Sponsorship sponsorship={sponsorship} typeName={typeName} />

              {!!sections[0] && (
                <Deals sectionUniqueName={sections[0].uniqueName} />
              )}

              {userSubmissionBox && (
                <SponsorshipBox
                  inPage={true}
                  buttonType="primary"
                  hideDivider={{ top: true }}
                  {...userSubmissionBox}
                />
              )}

              <TagContainer tags={tags} variant="bottom" />

              {configShareArticle.divider.top && <Divider />}

              <div className={styles.pageShareSingleContainer}>
                <PageShare
                  label={
                    configShareArticle.label
                      ? configShareArticle.label
                      : "Share this article"
                  }
                  url={`${configSite.address}${storyData.path}`}
                  title={titleElement?.value}
                  size={configSite.pageShare?.size}
                  media={configSite.pageShare?.media}
                  fill={configSite.pageShare?.fill}
                />
              </div>
              <OutbrainScript url={`${configSite.address}${storyData.path}`} />
            </div>
            <div
              className={styles.sidebar}
              style={{
                display: !isFeatureStory ? undefined : "none",
              }}
            >
              <Ads.AdsSideBar />
              <div className={styles.exploreMore} ref={exploreMoreRef}>
                {!!sections[0] && exploreMoreInView && (
                  <AppExploreMore
                    uniqSectionName={sections[0].uniqueName}
                    ignoreIds={id}
                    headingText={configExploreMore.heading}
                  />
                )}
              </div>
              <Ads.AdsSideBar />
            </div>
          </div>
        )}
        {hide ? (
          <div
            className={cx(
              styles.readMoreBlock,
              customClass?.singlePost?.readmore,
            )}
          >
            <Button
              variant="secondary"
              href={storyData.path}
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey || e.shiftKey) {
                  return;
                }
                e.preventDefault();
                const jsondata = JSON.stringify({
                  type: "event",
                  category: "Content-Engagement",
                  action: "click",
                  label: "Read More",
                  other: { path: storyData.path },
                });
                window.SPHMGTM.cmd.push(`send=${jsondata}`);
                setHide(false);
              }}
            >
              Read More
            </Button>
          </div>
        ) : null}
      </div>
      <AnalyticsArticle StoryData={storyData} />
    </article>
  );
}

export default SinglePostLayout;
