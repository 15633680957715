import styles from "./nestedlayout.module.css";

type NestedLayoutProps = {
  children: React.ReactNode;
};

const NestedLayout = ({ children }: NestedLayoutProps) => {
  return <div className={styles.nested}>{children}</div>;
};

export default NestedLayout;
