const PUBLIC_BASE_URL = import.meta.env.VITE_PUBLIC_BASE_URL;
export const IS_LOCAL_DEV = import.meta.env.VITE_IS_LOCAL_DEV === "true";
const IS_STG_SITE = PUBLIC_BASE_URL?.includes("web2-stg");
export const IS_DEV_SITE = PUBLIC_BASE_URL?.includes("web2-dev");
export const CAAS_INDEX_SUFFIX = "";

export const SHOW_DEBUG_INFO = IS_LOCAL_DEV || IS_DEV_SITE || IS_STG_SITE;

export const DISABLE_ADS = import.meta.env.VITE_DISABLE_ADS === "true";

export const GRAPESHOT_ENDPOINT = import.meta.env.VITE_GRAPESHOT_ENDPOINT;

export const ADS_TEXT_ORIGIN =
  "https://adtag.sphdigital.com/tag/ads/sphm/ads.txt";

export const AFFILIATE_DECLATION_DEFAULT_TEXT =
  "All the items are independently selected by our editorial team. If you buy something through our retail links, we may earn an affiliate commission. But we only recommend products we love. Promise.";

//This constant use to filter story set to public preview
export const PUBLIC_PREVIEW_EXCLUDE_FROM_HOME = "exclude_from_home";
export const PUBLIC_PREVIEW_MUST_NOT_CLAUSE = {
  match_phrase: {
    "data.context.displaySetting.displayAssetsMultiple":
      PUBLIC_PREVIEW_EXCLUDE_FROM_HOME,
  },
};
export const PUBLIC_PREVIEW_TEXT = "You are reading this in preview mode";

export const STORY_TYPES = [
  "onlineStory",
  "featureStory",
  "galleryStory",
  "videoStory",
  "brandedContent",
];

export const STORY_TYPE_FILTER = {
  bool: {
    minimum_should_match: 1,
    should: STORY_TYPES.map((type) => ({
      match: {
        "data.context.type": type,
      },
    })),
  },
};

export const STORY_TYPE_AND_CB_FILTER = {
  bool: {
    minimum_should_match: 1,
    should: [...STORY_TYPES, "topicStory"].map((type) => ({
      match: {
        "data.context.type": type,
      },
    })),
  },
};

export const AMAZON_AFFILIATES = {
  host: "webservices.amazon.sg",
  // Use us-west-2 for Singapore
  // https://webservices.amazon.com/paapi5/documentation/common-request-parameters.html#host-and-region
  region: "us-west-2",
};

export const SOURCE_FILTER = {
  // Workaround For Too Large Payload
  // https://sph.atlassian.net/browse/DMG-3212
  // - many alternates are repeated for every image but unused
  exclude: ["data.context.elements.relation.alternates"],
};

export const TYPE_BRANDED_CONTENT = "BrandedContent";
export const TYPE_FEATURE_STORY = "FeatureStory";
export const EXCLUDE_ADS = "ExcludeAds";

export const DEFAULT_SEARCH_SORT = "score";

export const INTERNAL_LINKS = [
  "www.sph.com.sg",
  "www.straitstimes.com",
  "www.businesstimes.com.sg",
  "stomp.straitstimes.com",
  "tnp.straitstimes.com",
  "www.zaobao.com.sg",
  "www.beritaharian.sg",
  "www.tamilmurasu.com.sg",
  "www.thinkchina.sg",
  "www.shinmin.sg",
  "www.tabla.com.sg",
  "www.herworld.com",
  "www.femalemag.com.sg",
  "www.nuyou.com.sg",
  "www.harpersbazaar.com",
  "www.womensweekly.com.sg",
  "www.iconsingapore.com",
  "www.homeanddecor.com.sg",
  "www.thepeakmagazine.com.sg",
  "www.hardwarezone.com.sg",
  "subscribe.sph.com.sg",
  "www.uweekly.sg",
  "www.heykaki.sg",
];

export const NEURON_CONFIG_NAME = "magazine";
