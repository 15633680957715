import { TeaserData } from "@app/types/ServerCustomContext";
import { ExploreMore } from "@sphtech/dmg-design-system";
import { AnchorBlockProps } from "packages/design-system/src/components/Module/AnchorBlock/AnchorBlock";
import { ReactNode } from "react";

import CategoryLayout from "../Category/CategoryLayout";

type Props = {
  data: {
    name: string;
    nameZH?: string;
    anchorBlock?: AnchorBlockProps;
    latestArticle?: TeaserData[];
    exploreMore?: React.ComponentProps<typeof ExploreMore>;
    mainContentBlock?: TeaserData[];
    lb1?: ReactNode;
    imu1?: ReactNode;
    imu2?: ReactNode;
    showVerticalLine?: boolean;
  };
};

function LatestStoryLayout({ data }: Props): ReactNode {
  return <CategoryLayout data={data} />;
}

export default LatestStoryLayout;
