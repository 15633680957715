import cx from "classnames";
import { ReactElement, useState } from "react";

import styles from "./AiSummaryStarRating.module.css";
import { Disclaimer } from "../../Element/Typography";
import { starRatingIcon } from "./AiSummaryIcon";
import { createLocalStorageHook } from "../../Utility/LocalStorage";

type AiSummaryStarRatingProps = {
  storyId: string;
  submitStarRating?: (rating: number) => void;
};

type AiSummaryRatings = {
  id: string; // story id
  rating: number;
}[];

function isAiSummaryRatings(ratings: unknown): ratings is AiSummaryRatings {
  return (
    Array.isArray(ratings) &&
    ratings.every(
      (rating) =>
        rating &&
        typeof rating === "object" &&
        "id" in rating &&
        typeof rating.id === "string" &&
        "rating" in rating &&
        typeof rating.rating === "number",
    )
  );
}

const AI_RATINGS = [1, 2, 3, 4, 5];

const useAiSummaryRatings = createLocalStorageHook(
  "ai-summary-ratings",
  [],
  isAiSummaryRatings,
);

export default function AiSummaryStarRating({
  storyId,
  submitStarRating,
}: AiSummaryStarRatingProps): ReactElement {
  const [savedRatings, setSavedRatings] = useAiSummaryRatings();

  const submittedRating =
    savedRatings?.find((rating) => rating.id === storyId)?.rating || 0;
  const [hover, setHover] = useState(0);

  const handleRatingChange = (currentRating: number) => {
    if (submitStarRating) {
      submitStarRating(currentRating);
    }

    const updatedRatings = savedRatings;
    if (!updatedRatings.some((r) => r.id === storyId)) {
      setSavedRatings([
        ...updatedRatings,
        { id: storyId, rating: currentRating },
      ]);
    }
  };

  return (
    <div className={styles.starRatingContainer}>
      <div className={styles.starRating}>
        {AI_RATINGS.map((currentRating) => {
          return (
            <label className={styles.ratingLabel} key={currentRating}>
              <input
                type="radio"
                name="rating"
                value={currentRating}
                onChange={() => handleRatingChange(currentRating)}
                disabled={!!submittedRating}
              />
              <span
                className={cx(
                  styles.starIconLabel,
                  currentRating <= (hover || submittedRating) &&
                    styles.starIconLabelFilled,
                )}
                onMouseEnter={() => !submittedRating && setHover(currentRating)}
                onMouseLeave={() => setHover(0)}
              >
                {starRatingIcon}
              </span>
            </label>
          );
        })}
      </div>
      {!!submittedRating && (
        <div className={styles.feedback}>
          <Disclaimer>Feedback Submitted</Disclaimer>
        </div>
      )}
    </div>
  );
}
