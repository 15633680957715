import ErrorLayout from "@app/layouts/ErrorLayout/ErrorLayout";
import { ContentHubData } from "@app/types/ServerCustomContext";
import AdSettings from "@app/utils/AdSettings";
import { mapTeaserDataToLatestProps } from "@app/utils/mapTeaserDataToProps";
import MetaData from "@components/Modules/MetaData";
import PubBaseLayout from "@pub/BaseLayout";
import { configContentHub } from "@pub/config";
import { GlobalBaseData } from "@sphtech/dmg-design-system";
import {
  CustomContext,
  ResponseType,
  TRouteWithRedirect,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import ContentHubLayout from "@src/app/layouts/ContentHub/ContentHubLayout";
import { useLocation } from "react-router-dom";

export default function ContentHub() {
  const routeContext: CustomContext<
    TRouteWithRedirect<ContentHubData & GlobalBaseData, string>
  > = useRouteContext();

  // Context from server.ts
  const { context: response } = routeContext;
  const location = useLocation();

  if (response.type !== ResponseType.SUCCESS) {
    return <ErrorLayout response={response} />;
  }

  const responseData = response.payload;
  const userSubmissionBox =
    responseData.userSubmissionBox?.showOn.topicPaths?.includes(
      location.pathname,
    )
      ? response.payload.userSubmissionBox
      : undefined;

  const displayVariant = responseData.displaySetting?.displayHeadline;

  const contentHubSingleConfig = configContentHub.layout?.stories;
  const contentHubVariantConfig =
    configContentHub.layout?.variant &&
    configContentHub.layout.variant.find(
      (variant) => variant.name === displayVariant,
    );

  return (
    <PubBaseLayout>
      <MetaData {...responseData.metaData} />
      <AdSettings
        pageType="contenthub"
        sectionName={responseData.name}
        adUnits="lb1,lb2,catfish,prestitial"
        pageTitle={responseData.name}
        currentLocation={location}
        StoryData={{ id: responseData.id }}
      />
      {contentHubSingleConfig?.elements && (
        <ContentHubLayout
          data={{
            ...responseData,
            listedStories:
              responseData.listedStories &&
              responseData.listedStories.map((teaser) =>
                mapTeaserDataToLatestProps(teaser),
              ),
            userSubmissionBox,
          }}
          layoutElementConfig={
            contentHubVariantConfig?.elements || contentHubSingleConfig.elements
          }
          ListingLayoutComponent={
            contentHubVariantConfig?.ListingLayoutComponent
          }
        />
      )}
    </PubBaseLayout>
  );
}
