import { SHOW_DEBUG_INFO } from "@app/constants";
import {
  CUEAnnotation,
  CUEAtomEntryGenericArticle,
  CUEElement,
  CUEPicture,
  CUERelationElementRelation,
} from "@app/types/Opensearch";
import { ImageCrop, SupportedCrops } from "@app/types/ServerCustomContext";
import { makeSvgDataSrc } from "@sphtech/dmg-design-system";
import { decodeHtmlEntity } from "@src/app/utils/htmlEntity";

export type ResolvedCUEAnnotation = Omit<CUEAnnotation, "value"> & {
  value?: ResolvedCUEElement | string;
};

type ResolvedFieldValueUnion = {
  annotations?: ResolvedCUEAnnotation[];
  value?: string | number | boolean;
};
export type ResolvedFieldValue = {
  annotations?: ResolvedCUEAnnotation[];
  value?: string;
};
type ResolvedFieldValueNumber = {
  value?: number;
};
type ResolvedFieldValueBoolean = {
  value?: boolean;
};

type ResolvedPlainCUEElement = Omit<
  CUEElement,
  "type" | "children" | "fields"
> & {
  type:
    | "affiliate_declaration"
    | "headline"
    | "standfirst"
    | "featured_image"
    | "first_image"
    | "mobile_image"
    | "slug"
    | "media_videos"
    | "page_break"
    | "image_compare"
    | "column_images"
    | "external_link"
    | "video_selection"
    | "list_bulleted"
    | "list_numbered"
    | "gallery_items"
    | "gallery_items_custom_html"
    | "promo_box"
    | "relation_profile"
    | "sponsorship_box"
    | "nested_elements";
  children?: ResolvedCUEElement[];
  value?: string;
  annotations?: ResolvedCUEAnnotation[];
  additionalFields: Record<string, ResolvedFieldValueUnion | undefined>;
};

type ResolvedPlainWithAnchorCUEElement = Omit<
  ResolvedPlainCUEElement,
  "type" | "additionalFields"
> & {
  type: "paragraph" | "grouped_relation";
  additionalFields: {
    htmlAnchor?: ResolvedFieldValue;
  };
};

type ImageCompareOrientation = "Horizontal" | "Vertical";
export type ResolvedImageCompareBeforeCUEElement = Omit<
  ResolvedPlainCUEElement,
  "type" | "additionalFields" | "relation"
> & {
  type: "image_compare_before";
  additionalFields: {
    beforeLabel?: ResolvedFieldValue;
    orientation?: {
      value: ImageCompareOrientation;
    };
    offsetPercentage?: ResolvedFieldValue;
    noOverlay?: ResolvedFieldValueBoolean;
  };
  relation: CUEPicture | undefined;
};

export type ResolvedImageCompareAfterCUEElement = Omit<
  ResolvedPlainCUEElement,
  "type" | "additionalFields" | "relation"
> & {
  type: "image_compare_after";
  additionalFields: {
    afterLabel?: ResolvedFieldValue;
  };
  relation: CUEPicture | undefined;
};

export type ResolvedSubHeadingCUEElement = Omit<
  ResolvedPlainCUEElement,
  "type" | "additionalFields"
> & {
  type: "sub_head";
  additionalFields: {
    headings?: {
      value: "h2" | "h3" | "h4" | "h5" | "h6";
      annotations: ResolvedCUEAnnotation[];
    };
    subhead?: {
      value: string;
      annotations: ResolvedCUEAnnotation[];
    };
    htmlAnchor?: {
      value: string;
    };
  };
};

export type ResolvedImageCUEElement = Omit<
  ResolvedPlainCUEElement,
  "type" | "additionalFields" | "relation"
> & {
  type: "image";
  additionalFields: {
    title?: ResolvedFieldValue;
    altText?: ResolvedFieldValue;
    description?: ResolvedFieldValue;
    alignment?: ResolvedFieldValue;
    caption?: ResolvedFieldValue;
    convertToParallax?: ResolvedFieldValue;
    credit?: ResolvedFieldValue;
    imageLink?: ResolvedFieldValue;
    cropSelection?: {
      value: SupportedCrops;
    };
    displayCaption?: ResolvedFieldValue;
    displayFullWidth?: ResolvedFieldValue;
    titleColor?: ResolvedFieldValue;
    htmlAnchor?: ResolvedFieldValue;
  };
  relation: CUEPicture | undefined;
};

export type ResolvedTableCUEElement = Omit<
  ResolvedPlainCUEElement,
  "type" | "additionalFields"
> & {
  type: "table";
  additionalFields: {
    tableeditor?: {
      value: string;
    };
    rowheader?: {
      value: boolean;
    };
    columnheader?: {
      value: boolean;
    };
  };
};

export type ResolvedEmbedCUEElement = Omit<
  ResolvedPlainCUEElement,
  "type" | "additionalFields"
> & {
  type: "embed";
  additionalFields: {
    uri?: ResolvedFieldValue;
    width?: ResolvedFieldValue;
    height?: ResolvedFieldValue;
    htmlAnchor?: ResolvedFieldValue;
  };
};

export type ResolvedContentListCUEElement = Omit<
  ResolvedPlainCUEElement,
  "type" | "additionalFields"
> & {
  type: "content_list";
  additionalFields: {
    headerText?: ResolvedFieldValue;
    cnHeaderText?: ResolvedFieldValue;
    htmlAnchor?: ResolvedFieldValue;
    cssClass?: ResolvedFieldValue;
  };
};

export type ResolvedContentListItemInfoCUEElement = Omit<
  ResolvedPlainCUEElement,
  "type" | "additionalFields"
> & {
  type: "content_list_item_info";
  additionalFields: {
    customTitle?: ResolvedFieldValue;
    customtUrl?: ResolvedFieldValue; // tmp support CUE typo
    customUrl?: ResolvedFieldValue;
    customCategoryName?: ResolvedFieldValue;
    customtCategoryUrl?: ResolvedFieldValue; // tmp support CUE typo
    customCategoryUrl?: ResolvedFieldValue;
    customDescription?: ResolvedFieldValue;
  };
};

export type ResolvedContentListItemRelationCUEElement = Omit<
  ResolvedPlainCUEElement,
  "type" | "relation"
> & {
  type: "relation_content_list_item";
  additionalFields: {
    customTitle?: ResolvedFieldValue;
    customUrl?: ResolvedFieldValue;
    customtUrl?: ResolvedFieldValue; // typo in CUE
    customCategoryName?: ResolvedFieldValue;
    customCategoryUrl?: ResolvedFieldValue;
    customtCategoryUrl?: ResolvedFieldValue; // typo in CUE
    customDescription?: ResolvedFieldValue;
  };
  relation?: {
    __typename: string;
    type?: string;
    id?: string;
    source?: string;
    sourceId?: string;
  };
};

type GalleryItemInfoFields = {
  title?: ResolvedFieldValue;
  targetUrl?: ResolvedFieldValue;
  credit?: ResolvedFieldValue;
  description?: ResolvedFieldValue;
};

export type ResolvedGalleryItemHtmlInfoCUEElement = Omit<
  ResolvedPlainCUEElement,
  "type" | "additionalFields"
> & {
  type: "gallery_items_custom_html_info";
  additionalFields: GalleryItemInfoFields;
};

export type ResolvedGalleryItemImageCUEElement = Omit<
  ResolvedPlainCUEElement,
  "type" | "additionalFields" | "relation"
> & {
  type: "gallery_items_image";
  additionalFields: GalleryItemInfoFields;
  relation?: CUEPicture;
};

export type ResolvedBrightcoveCUEElement = Omit<
  ResolvedPlainCUEElement,
  "type" | "additionalFields"
> & {
  type: "brightcove_video";
  additionalFields: {
    description?: ResolvedFieldValue;
    title?: ResolvedFieldValue;
    brightcoveId?: ResolvedFieldValue;
  };
};

export type ResolvedYouTubeCUEElement = Omit<
  ResolvedPlainCUEElement,
  "type" | "additionalFields"
> & {
  type: "youtube_video";
  additionalFields: {
    description?: ResolvedFieldValue;
    title?: ResolvedFieldValue;
    youtubeId?: ResolvedFieldValue;
  };
};

export type ResolvedButtonElement = Omit<
  ResolvedPlainCUEElement,
  "type" | "additionalFields"
> & {
  type: "shop_button" | "call_to_action" | "call_to_action_text";
  additionalFields: {
    name?: ResolvedFieldValue;
    websiteLink?: ResolvedFieldValue;
    destinationUrl?: ResolvedFieldValue;
    eventActionContentEngagement?: ResolvedFieldValue;
    eventCategory?: ResolvedFieldValue;
    eventLabel?: ResolvedFieldValue;
    textToDisplay?: ResolvedFieldValue;
  };
};

export type ResolvedCallToActionImageElement = Omit<
  ResolvedPlainCUEElement,
  "type" | "additionalFields" | "relation"
> & {
  type: "call_to_action_image";
  additionalFields: {
    destinationUrl?: ResolvedFieldValue;
    eventActionContentEngagement?: ResolvedFieldValue;
    eventCategory?: ResolvedFieldValue;
    eventLabel?: ResolvedFieldValue;
  };
  relation?: CUEPicture;
};

export type ResolvedExternalLinkElement = Omit<
  ResolvedPlainCUEElement,
  "type" | "additionalFields"
> & {
  type: "external_link";
  additionalFields: {
    newWindow?: ResolvedFieldValueBoolean;
    noFollow?: ResolvedFieldValueBoolean;
    uri?: ResolvedFieldValue;
  };
};

type ResolvedAnchorLinkElement = Omit<
  ResolvedPlainCUEElement,
  "type" | "additionalFields"
> & {
  type: "anchor_link";
  additionalFields: {
    anchor?: ResolvedFieldValue;
    newWindow?: ResolvedFieldValueBoolean;
  };
};

export function isAnchorLinkElement(
  value?: unknown,
): value is ResolvedAnchorLinkElement {
  return (
    !!value &&
    typeof value === "object" &&
    "type" in value &&
    value.type === "anchor_link"
  );
}

export type ResolvedInternalLinkElement = Omit<
  ResolvedPlainCUEElement,
  "type" | "additionalFields" | "relation"
> & {
  type: "internal_link";
  additionalFields: {
    newWindow?: ResolvedFieldValueBoolean;
    noFollow?: ResolvedFieldValueBoolean;
  };
  relation?: {
    id?: string;
    type?: string;
    urlPath?: string;
  };
};

export type ResolvePullQuoteElement = Omit<
  ResolvedPlainCUEElement,
  "type" | "additionalFields"
> & {
  type: "pull_quote";
  additionalFields: {
    quote?: ResolvedFieldValue;
    quotee?: ResolvedFieldValue;
    quoteLabel?: ResolvedFieldValue;
    quoteRule?: ResolvedFieldValue;
  };
};

export type ResolveSponsorshipBoxInfoElement = Omit<
  ResolvedPlainCUEElement,
  "type" | "additionalFields"
> & {
  type: "sponsorship_box_info";
  additionalFields: {
    title?: ResolvedFieldValue;
    description?: ResolvedFieldValue;
    cta_text?: ResolvedFieldValue;
    cta_url?: ResolvedFieldValue;
  };
};

export type ResolvedContentHubElement = Omit<
  ResolvedPlainCUEElement,
  "type" | "additionalFields"
> & {
  type:
    | "for_editor"
    | "column_images"
    | "for_editor_note"
    | "column_images_item"
    | "column_images_item_info"
    | "content_list_item";
  additionalFields: {
    paragraph?: ResolvedFieldValue;
    relation?: ResolvedFieldValue;
    displayDropCap?: ResolvedFieldValueBoolean;
    disableAutolink?: ResolvedFieldValueBoolean;
    targetUrl?: ResolvedFieldValue;
    title?: ResolvedFieldValue;
    uri?: ResolvedFieldValue;
    htmlAnchor?: ResolvedFieldValue;
  };
};

type ResolvedContentHubVideoSelectionElement = Omit<
  ResolvedPlainCUEElement,
  "type" | "relation"
> & {
  type: "video_selection_content_list";
  relation: CUEAtomEntryGenericArticle | undefined;
};

type ResolvedRelationElement = Omit<
  ResolvedPlainCUEElement,
  "type" | "relation"
> & {
  type: "relation";
  relation: CUERelationElementRelation | undefined;
};

type ResolvedContentHubImageElements = Omit<
  ResolvedPlainCUEElement,
  "type" | "relation"
> & {
  type: "for_editor_image" | "for_editor_signature";
  relation: CUEPicture | undefined;
};

export type ResolvedColumnImageElement = Omit<
  ResolvedPlainCUEElement,
  "type" | "additionalFields"
> & {
  type: "column_images_item_info";
  additionalFields: {
    targetUrl?: ResolvedFieldValue;
    title?: ResolvedFieldValue;
  };
};

export type ResolvedAffiliateDeclarationInfoElement = Omit<
  ResolvedPlainCUEElement,
  "type" | "additionalFields"
> & {
  type: "affiliate_declaration_info";
  additionalFields: {
    declaration?: ResolvedFieldValueBoolean;
    text?: ResolvedFieldValue;
  };
};

export type ResolvedDealsElement = Omit<
  ResolvedPlainCUEElement,
  "type" | "additionalFields"
> & {
  type: "deals";
  additionalFields?: {
    destinationUrl?: ResolvedFieldValue;
    discount?: ResolvedFieldValueNumber;
    discountedPrice?: ResolvedFieldValueNumber;
    price?: ResolvedFieldValueNumber;
    sourceName?: ResolvedFieldValue;
    dynamicPricing?: ResolvedFieldValue;
  };
};

export type ResolvedProductElement = Omit<ResolvedPlainCUEElement, "type"> & {
  type: "product_name";
  additionalFields: {
    value?: ResolvedFieldValue;
  };
};

export type ResolvedAmazonDynamicPricingElement = Omit<
  ResolvedPlainCUEElement,
  "type" | "additionalFields"
> & {
  type: "amazon_dynamic_pricing";
  additionalFields: {
    text?: ResolvedFieldValue;
  };
};

export type ResolvedPromoBoxInfoElement = Omit<
  ResolvedPlainCUEElement,
  "type" | "additionalFields"
> & {
  type: "promo_box_info";
  additionalFields: {
    promoActive?: ResolvedFieldValueBoolean;
    promoLabelName?: ResolvedFieldValue;
    promoBoxLabelName?: ResolvedFieldValue;
    promoBoxHeading?: ResolvedFieldValue;
  };
};

export type ResovledAuthorProfileElement = Omit<
  ResolvedPlainCUEElement,
  "type" | "relation"
> & {
  type: "relation_profile";
  relation: CUERelationElementRelation | undefined;
  resolvedData?: {
    image?: ImageCrop;
    name: string;
    title: string;
    description: string;
    urlPath: string;
  };
};

export type ResolvedProductListing = Omit<
  ResolvedPlainCUEElement,
  "type" | "additionalFields"
> & {
  type: "product_listing";
  additionalFields: {
    items_per_page?: ResolvedFieldValueNumber;
    title?: ResolvedFieldValue;
  };
};

export type ResolvedProductInfo = Omit<
  ResolvedPlainCUEElement,
  "type" | "additionalFields"
> & {
  type: "product_info";
  additionalFields: {
    discountText?: ResolvedFieldValue;
    headerTitle?: ResolvedFieldValue;
  };
};

export type ResolvedProductReview = Omit<ResolvedPlainCUEElement, "type"> & {
  type: "product_pro" | "product_con";
};

export type ResolvedHorzontalRuleElement = Omit<
  ResolvedPlainCUEElement,
  "type"
> & {
  type: "horizontal_row";
};

export type ResolvedDynamicIframeElement = Omit<
  ResolvedPlainCUEElement,
  "type" | "additionalFields"
> & {
  type: "dynamic_iframe_wrapper";
  additionalFields: {
    dynamicIframe?: ResolvedFieldValue;
  };
};

export type ResolvedCUEElement =
  | ResolvedSubHeadingCUEElement
  | ResolvedImageCUEElement
  | ResolvedImageCompareBeforeCUEElement
  | ResolvedImageCompareAfterCUEElement
  | ResolvedGalleryItemHtmlInfoCUEElement
  | ResolvedGalleryItemImageCUEElement
  | ResolvedButtonElement
  | ResolvedExternalLinkElement
  | ResolvedInternalLinkElement
  | ResolvePullQuoteElement
  | ResolveSponsorshipBoxInfoElement
  | ResolvedContentHubElement
  | ResolvedContentHubVideoSelectionElement
  | ResolvedContentHubImageElements
  | ResolvedColumnImageElement
  | ResolvedPlainCUEElement
  | ResolvedPlainWithAnchorCUEElement
  | ResolvedRelationElement
  | ResolvedEmbedCUEElement
  | ResolvedContentListCUEElement
  | ResolvedContentListItemInfoCUEElement
  | ResolvedContentListItemRelationCUEElement
  | ResolvedAffiliateDeclarationInfoElement
  | ResolvedCallToActionImageElement
  | ResolvedDealsElement
  | ResolvedBrightcoveCUEElement
  | ResolvedYouTubeCUEElement
  | ResolvedAmazonDynamicPricingElement
  | ResolvedProductElement
  | ResolvedTableCUEElement
  | ResolvedPromoBoxInfoElement
  | ResovledAuthorProfileElement
  | ResolvedProductListing
  | ResolvedProductInfo
  | ResolvedProductReview
  | ResolvedHorzontalRuleElement
  | ResolvedDynamicIframeElement
  | ResolvedAnchorLinkElement;

const mastheadElementTypes = [
  "headline",
  "standfirst",
  "featured_image",
  "first_image",
  "mobile_image",
  "media_videos",
];
const ignoreElementTypes = ["slug", "page_break"];

export const processElements = (cueElements?: CUEElement[]) => {
  const elements = resolveElements(cueElements);
  const mastheadElements = elements.filter((ele) =>
    mastheadElementTypes.includes(ele.type),
  );

  const bodyElements = elements.filter((ele) => {
    return (
      !mastheadElements.includes(ele) && !ignoreElementTypes.includes(ele.type)
    );
  });

  const titleElement = mastheadElements.find((ele) => ele.type === "headline");

  const standfirstElement = mastheadElements.find(
    (ele) => ele.type === "standfirst",
  );
  const featuredImageElement = mastheadElements.find(
    (ele) => ele.type === "featured_image",
  );
  const firstImageElement = mastheadElements.find(
    (ele) => ele.type === "first_image",
  );
  const mobileImageElement = mastheadElements.find(
    (ele) => ele.type === "mobile_image",
  );
  const videoElement = mastheadElements.find(
    (ele) => ele.type === "media_videos",
  );

  const headline = titleElement?.value || "";
  const standfirst = standfirstElement?.value || "";
  const dealsElement = bodyElements.find(
    (ele): ele is ResolvedDealsElement => ele.type === "deals",
  );
  //@todo create a dedicated handler for product related stuff
  const productElement = bodyElements.find(
    (ele): ele is ResolvedProductElement => ele.type === "product_name",
  );
  const productName =
    productElement?.additionalFields["headline"]?.value?.toString();

  const featuredImage =
    isCUEImageElement(featuredImageElement?.children?.[0]) &&
    featuredImageElement.children[0].relation
      ? featuredImageElement.children[0]
      : // If first/featured image is not available, loop thru bodyElements and get the first image found.
        // Find element's type = "image" and return the first image found.
        bodyElements.find(
          (ele): ele is ResolvedImageCUEElement => ele.type === "image",
        );

  const firstImage =
    isCUEImageElement(firstImageElement?.children?.[0]) &&
    firstImageElement.children[0].relation
      ? firstImageElement.children[0]
      : isCUEImageElement(featuredImageElement?.children?.[0]) &&
        featuredImageElement.children[0];

  return {
    headline,
    headlineZH: "",
    standfirst,
    titleElement,
    standfirstElement,
    videoElement: videoElement?.children?.[0],
    coverImage: firstImage
      ? {
          crops: {
            // mobile featured story & topic story
            square_30_26: resolveImageCrop(firstImage, "square_30_26"),
            // rest
            original: resolveImageCrop(firstImage, "original"),
            // og:image fallback
            landscape: resolveImageCrop(firstImage, "landscape"),
          },
          title: decodeHtmlEntity(firstImage.additionalFields.title?.value),
          caption: firstImage.relation?.fields.caption,
          credit: firstImage.relation?.fields.credit,
        }
      : undefined,
    mastheadElements,
    dealsElement,
    bodyElements,
    // used for listing pages
    featuredImage: featuredImage
      ? {
          crops: {
            // desktop anchor block and landscape listing
            landscape: resolveImageCrop(featuredImage, "landscape"),
            // desktop portrait listing
            portrait: resolveImageCrop(featuredImage, "portrait"),
            // used for mobile listing
            original: resolveImageCrop(featuredImage, "original"),
            // used for mobile anchor blocks
            square_30_26: resolveImageCrop(featuredImage, "square_30_26"),
            // used for desktop trending, contenthub widget in home and 404
            square: resolveImageCrop(featuredImage, "square"),
          },
          alt: featuredImage.additionalFields.altText?.value || "",
        }
      : undefined,
    mobileImageElement,
    // used for product
    productName,
  };
};

export type ProcessedElements = ReturnType<typeof processElements>;

export const resolveElements = (originalElementsArg?: CUEElement[]) => {
  if (!originalElementsArg) {
    console.error("[resolveElements] recieved falsy elements");
  }
  const originalElements = originalElementsArg || [];
  // copy elements to allow mutating
  const resolvedElementsById = new Map<string, ResolvedCUEElement>(
    originalElements.map((ele) => [
      ele.id,
      {
        id: ele.id,
        type: ele.type,
        additionalFields: {},
        relation: ele.relation,
      } as ResolvedCUEElement,
    ]),
  );

  const childrenIds = new Set(
    originalElements.map((ele) => ele.children).flat(),
  );

  const resolveAnnotation = (annotation: CUEAnnotation) => {
    const elementFromValue = resolvedElementsById.get(annotation.value);
    if (elementFromValue) {
      childrenIds.add(annotation.value);
      return {
        ...annotation,
        value: elementFromValue,
      } as ResolvedCUEAnnotation;
    }
    return annotation as ResolvedCUEAnnotation;
  };

  return originalElements
    .map((ele) => {
      const resolvedElement = resolvedElementsById.get(ele.id);
      if (!resolvedElement) {
        throw new Error(`[resolveElements] Missing element ${ele.id}`);
      }
      if (ele.children) {
        resolvedElement.children = ele.children.map((id) => {
          const resolvedChild = resolvedElementsById.get(id);
          if (!resolvedChild) {
            throw new Error(`[resolveElements] Missing element child ${id}`);
          }
          return resolvedChild;
        });
      }

      resolvedElement.additionalFields = ele.fields.reduce<
        Record<string, ResolvedFieldValueUnion>
      >((additionalFields, field) => {
        if (field.name === ele.type) {
          resolvedElement.value = field.value;
          resolvedElement.annotations =
            field.annotations?.map(resolveAnnotation);
        } else {
          additionalFields[field.name] = {
            value: field.value ?? field.booleanValue ?? field.numberValue,
            annotations: field.annotations?.map(resolveAnnotation),
          };
        }
        return additionalFields;
      }, {});
      return resolvedElement;
    })
    .filter((ele) => !childrenIds.has(ele.id));
};

export function isCUEImageElement(
  input?: ResolvedCUEElement,
): input is ResolvedImageCUEElement {
  return !!input && input.type === "image";
}

export function isCuePicture(relation?: unknown): relation is CUEPicture {
  return (
    !!relation &&
    typeof relation === "object" &&
    "fields" in relation &&
    "type" in relation &&
    relation.type === "picture"
  );
}

// simpler picture check for author profile graphql because CUE is missed returning type=picture for headshots
export function isCueAuthorHeadShot(
  relation?: unknown,
): relation is CUEPicture {
  return !!relation && typeof relation === "object" && "fields" in relation;
}

const isValidURL = (url: string) => {
  if (url.startsWith("/sampleImages/")) {
    return true;
  }
  try {
    return new URL(url);
  } catch (e) {
    return undefined;
  }
};

export const extractCrop = (
  picture: CUEPicture | undefined,
  cropSelection: SupportedCrops,
  alt?: string,
  credit?: string,
): ImageCrop | undefined => {
  if (!picture) {
    return;
  }
  const caasCrop = picture.fields[`${cropSelection}-caas`];
  const cueCrop = picture.fields[cropSelection];
  const fallbackCrop = SHOW_DEBUG_INFO
    ? {
        selection: cropSelection,
        src: makeSvgDataSrc({
          width: 500,
          height: 350,
          text: `CaaS image missing: ${cropSelection} pic#${picture.id}`,
        }),
        srcWidth: 500,
        srcHeight: 350,
        alt: JSON.stringify(picture),
      }
    : undefined;

  if (!cueCrop) {
    return;
  }
  if (!caasCrop?.url) {
    const error = new Error(
      `CaaS image url missing for ${cropSelection} crop in picture #${picture.id}`,
    );
    console.warn(error);
    return fallbackCrop;
  } else if (!isValidURL(caasCrop.url)) {
    //encountered issue where caas url dosen't return proper URL. So, added a check to validate the URL
    const error = new Error(
      `CaaS invalid image url ${caasCrop.url} for ${cropSelection} crop in picture #${picture.id}`,
    );
    console.warn(error);
    return fallbackCrop;
  } else if (!caasCrop.height) {
    console.warn(
      `CaaS image height missing for ${cropSelection} crop in picture #${picture.id}`,
    );
  }

  return {
    selection: cropSelection,
    src: caasCrop.url,
    srcWidth: caasCrop.width || cueCrop.width,
    srcHeight: caasCrop.height || cueCrop.height,
    alt: alt || picture.fields.caption || "",
    credit: credit || picture.fields.credit || undefined,
  };
};

export const resolveImageCrop = (
  element: ResolvedImageCUEElement,
  fixedCropSelection?: SupportedCrops,
): ImageCrop | undefined => {
  const { additionalFields } = element;
  const relation = element.relation;

  const cropSelection =
    fixedCropSelection || additionalFields.cropSelection?.value;
  if (!cropSelection || !relation) {
    return;
  }
  return extractCrop(
    relation,
    cropSelection,
    additionalFields.altText?.value,
    additionalFields.credit?.value,
  );
};

// Function is extracted from EmbeddedGalleryElement.tsx
export const getGalleryItemInfo = (elements: ResolvedCUEElement[]) => {
  const infoFields =
    elements.find(
      (e): e is ResolvedGalleryItemHtmlInfoCUEElement =>
        e.type === "gallery_items_custom_html_info",
    )?.additionalFields ||
    elements.find(
      (e): e is ResolvedGalleryItemImageCUEElement =>
        e.type === "gallery_items_image",
    )?.additionalFields;

  return infoFields;
};

export type DynamicIframe = {
  templateID: string;
  data: { name?: string; value?: string }[];
};

export const isDynamicIframe = (value?: unknown): value is DynamicIframe => {
  return (
    !!value &&
    typeof value === "object" &&
    "templateID" in value &&
    typeof value.templateID === "string" &&
    "data" in value &&
    Array.isArray(value.data) &&
    value.data.every((datum) => datum && typeof datum === "object")
  );
};
