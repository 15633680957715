import { GRAPESHOT_ENDPOINT } from "@src/app/constants";
import { Helmet } from "react-helmet-async";

export default function AnalyticsGrapeshot() {
  if (!GRAPESHOT_ENDPOINT) {
    return;
  }
  return (
    <Helmet>
      <script type="text/javascript">
        {`(function loadGrapeshotSEF() {
  window.gpt_grapeshots_promise = fetch(
    "${GRAPESHOT_ENDPOINT}?url=" + encodeURIComponent(location.origin + location.pathname),
    {signal: "AbortSignal" in window && typeof AbortSignal.timeout === "function" ? AbortSignal.timeout(5000) : undefined}
  ).then((response) => response.json()).then((data) => {
    const dataObject = data && typeof data === "object" ? data : {};
    const channels = Array.isArray(dataObject.channels) ? dataObject.channels.filter(
      (c) => !!c && typeof c === "object" && "name" in c && typeof c.name === "string"
    ).map((c) => c.name) : [];
    window.gpt_grapeshots = channels.length ? channels : ["no_category"];
    window.gpt_grapeshots_fr = dataObject.fr;
    const meta = document.createElement("meta");
    meta.id = "grapeshot";
    meta.name = "grapeshot_tag";
    meta.content = channels.join(",");
    document.head.appendChild(meta);
  });
})()`}
      </script>
    </Helmet>
  );
}
