import type { DataSource } from "@pub/config";
import {
  AnchorBlock,
  GlobalBaseData,
  ImageCrop,
} from "@sphtech/dmg-design-system";
import { TeaserData } from "@src/app/types/ServerCustomContext";
import { HomeElementConfigData } from "@src/app/utils/homeConfig";

export type HomeContentBlocks = {
  uniqSectionName?: string;
  key?: string;
  title: string;
  titleZH?: string;
  url: string;
  items: TeaserData[];
  withImu?: boolean;
  cta?: boolean;
  config?: HomeElementConfigData;
  backgroundImage?: ImageCrop;
};

export type HomeLayoutProps = {
  data: {
    AnchorBlockProps?: React.ComponentProps<typeof AnchorBlock>;
    latestArticle: {
      items: TeaserData[];
      viewMore?: {
        url: string;
        text: string;
      };
    };
    contentBlock: HomeContentBlocks[];
    socialVideo?: Omit<HomeContentBlocks, "items"> & {
      items: {
        embedUrl: string;
      }[];
    };
    magazineIssues: Omit<HomeContentBlocks, "items"> & {
      subscriptionInfo?: {
        heading?: string;
        description?: string;
        buttonUrl?: string;
        buttonText?: string;
      };
      items: {
        url: string;
        caption: string;
        image: string;
        srcWidth: number;
        srcHeight: number;
        alt: string;
      }[];
    };
    userSubmissionBox?: GlobalBaseData["userSubmissionBox"];
  };
};

export function extractHomeContentData(
  data: HomeLayoutProps["data"],
  dataSource: DataSource,
) {
  return data.contentBlock.find((item) => item.key === dataSource.key);
}
