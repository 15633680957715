import { configHome, configTeaser, DataSource } from "@pub/config";
import placeholders from "@pub/placeholders";
import {
  Ads,
  AnchorBlock,
  ContentBlock,
  LatestArticle,
  ResponsiveImage,
  SectionContext,
  useCustomClassContext,
} from "@sphtech/dmg-design-system";
import { sectionUniqNameToPath } from "@src/app/utils/section";
import cx from "classnames";
import { ReactNode } from "react";

import styles from "../homelayout.module.css";
import { extractHomeContentData, HomeLayoutProps } from "./utils";

type Props = {
  data: HomeLayoutProps["data"];
  dataSource: DataSource;
  alternateBg?: boolean;
};

/**
 * Description: Content Block 3 items with IMU | Content Block F (3 tiles with IMU)
 */
export default function HomeContentBlockF({
  data,
  dataSource,
  alternateBg,
}: Props): ReactNode {
  const customClass = useCustomClassContext();
  const ContentBlockThreeItemsWithImu = extractHomeContentData(
    data,
    dataSource,
  );

  const userSubmissionBox =
    configHome.contentBlockF?.userSubmissionBox &&
    dataSource.key &&
    data.userSubmissionBox?.showOn.sectionPaths?.includes(
      sectionUniqNameToPath(dataSource.key),
    )
      ? data.userSubmissionBox
      : undefined;

  return (
    !!ContentBlockThreeItemsWithImu?.items.length && (
      <div
        className={cx(
          alternateBg && styles.backgroundFill,
          styles.gutter,
          styles.contentBlockFContainer,
          customClass?.homeContentBlockF?.sectionHeading,
        )}
        style={
          ContentBlockThreeItemsWithImu.config && {
            background: ContentBlockThreeItemsWithImu.config.background,
          }
        }
      >
        {ContentBlockThreeItemsWithImu.backgroundImage && (
          <div className={styles.backgroundImage}>
            <ResponsiveImage
              {...ContentBlockThreeItemsWithImu.backgroundImage}
              fill="cover"
              loading="lazy"
              displayWidth={1000}
              mobileUp={{
                ...ContentBlockThreeItemsWithImu.backgroundImage,
                displayWidth: 1920,
              }}
            />
          </div>
        )}
        <div
          className={cx(styles.bodyContainer, styles.contentBlockOverrideColor)}
        >
          <ContentBlock
            heading={ContentBlockThreeItemsWithImu.title}
            headingZH={ContentBlockThreeItemsWithImu.titleZH}
            cta={{
              name: configHome.contentBlockF?.viewMore?.text || "VIEW ALL",
              url: ContentBlockThreeItemsWithImu.url,
              icon: configHome.viewMore?.icon,
            }}
            imu={!configHome.contentBlockF?.hideAds && <Ads.AdsSideBar />}
            withDivider={configHome.headingDivider}
            isHeadingClickable={configHome.isHeadingClickable}
            userSubmissionBox={userSubmissionBox}
          >
            <SectionContext.Provider
              value={{
                uniqueName: configHome.contentBlockF?.skipSectionContext
                  ? undefined
                  : ContentBlockThreeItemsWithImu.uniqSectionName,
              }}
            >
              <div className={styles.contentBlockThreeInnerContainer}>
                <div
                  className={cx(
                    styles.contentBlockThreeItemsContainer,
                    styles.latestTeaserFontOverride,
                  )}
                >
                  {ContentBlockThreeItemsWithImu.items.map((item, index) => {
                    const isItemLandscape = configHome.contentBlockF
                      ?.landscapeIndexes
                      ? configHome.contentBlockF.landscapeIndexes.includes(
                          index,
                        )
                      : index === 2;

                    const isItemOriginal =
                      configHome.contentBlockF?.originalIndexes &&
                      configHome.contentBlockF.originalIndexes.includes(index);

                    const isItemLayoutTwoColumn =
                      configHome.contentBlockF?.layoutTwoColumn &&
                      configHome.contentBlockF.layoutTwoColumn.includes(index);

                    const isItemLayoutOneColumn =
                      configHome.contentBlockF?.anchorBlock?.index === index;

                    const modeStyle = () => {
                      if (isItemOriginal) {
                        return styles.original;
                      } else if (isItemLandscape && isItemLayoutTwoColumn) {
                        return styles.landscapeTwoColumn;
                      } else if (isItemLandscape) {
                        return styles.landscape;
                      }
                      return styles.portrait;
                    };

                    const itemTeaserArticle =
                      isItemLayoutOneColumn &&
                      configHome.contentBlockF?.anchorBlock?.mediaScreen ===
                        "all-screen" ? (
                        <AnchorBlock
                          {...item}
                          loading="lazy"
                          placeholder={placeholders.landscape}
                          mobileCrop={configTeaser.anchorBlock?.mobileCrop}
                          hideVerticalLine={
                            configHome.contentBlockF.anchorBlock
                              .hideVerticalLine
                          }
                          CustomHeadingElement="h3"
                        />
                      ) : (
                        <LatestArticle
                          {...item}
                          sections={
                            configHome.contentBlockF?.skipTeaserSection
                              ? []
                              : item.sections
                          }
                          mode={
                            isItemOriginal
                              ? "original"
                              : isItemLandscape
                                ? "landscape"
                                : "portrait"
                          }
                          mobileMode={configHome.contentBlockF?.mobileMode}
                          placeholders={placeholders}
                          landscapeImageWidth={
                            isItemLayoutTwoColumn
                              ? configHome.contentBlockF
                                  ?.twoColumnsLandscapeImageWidth || 315
                              : configHome.contentBlockF?.landscapeImageWidth ||
                                630
                          }
                          portraitImageWidth={
                            configHome.contentBlockF?.portraitImageWidth || 315
                          }
                          displayPublishDate={configTeaser.publishDate}
                        />
                      );

                    return (
                      <div
                        key={index}
                        className={cx(
                          styles.contentBlockThreeItems,
                          modeStyle(),
                          isItemLayoutOneColumn && styles.anchorBlock,
                        )}
                      >
                        {!isItemLayoutOneColumn ? (
                          <div className={styles.mDownGutterSides}>
                            {itemTeaserArticle}
                          </div>
                        ) : (
                          itemTeaserArticle
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </SectionContext.Provider>
          </ContentBlock>
        </div>
      </div>
    )
  );
}
