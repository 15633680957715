/**
 * https://github.com/SPHTech/web2-mag/pull/1277
 * Reverted back to Neuron version ^1.14.3
 * See below for Neuron version ^2.0.0-rc.12
 */
import { NeuronProvider as Provider, SPAConfig } from "@sphtech/neuron";
import { ReactNode } from "react";

import TrackerProvider from "./TrackerProvider";

type Props = {
  children: ReactNode;
  configName: string;
};

export default function NeuronProvider({ children }: Props): ReactNode {
  // Disable Neuron in CUE preview
  // https://sph.atlassian.net/browse/DMG-3774
  if (
    typeof window !== "undefined" &&
    window.location.search.includes("preview_token=")
  ) {
    return children;
  }

  const neuronConfig: SPAConfig = {
    trackedSPAEvents: [
      { eventType: "click", selector: "button, a" },
      {
        eventType: "elementVisible",
        selector: "div[id^='sph_cdp_'], div[id^='dfp-ad-']",
      },
    ],
    sessionApiUrl: import.meta.env.VITE_NEURON_API_URL,
    sessionApiToken: import.meta.env.VITE_NEURON_API_TOKEN,
    // Allow Neuron to pull article metadata from datalayer instead of _data
    experimental: {
      articleMetadata: {
        useDataLayer: true,
      },
    },
  };

  return (
    <Provider config={neuronConfig}>
      <TrackerProvider>{children}</TrackerProvider>
    </Provider>
  );
}

/**
 * Neuron version ^2.0.0-rc.12
 */
// import { NeuronProvider as Provider, type SPAConfig } from "@sphtech/neuron";
// import { ReactNode } from "react";

// type Props = {
//   children: ReactNode;
//   configName: string;
// };

// export default function NeuronProvider({
//   children,
//   configName,
// }: Props): ReactNode {
//   // Disable Neuron in CUE preview
//   // https://sph.atlassian.net/browse/DMG-3774
//   if (
//     typeof window !== "undefined" &&
//     window.location.search.includes("preview_token=")
//   ) {
//     return children;
//   }

//   const neuronConfig: SPAConfig = {
//     sessionApiUrl: import.meta.env.VITE_NEURON_API_URL,
//     sessionApiToken: import.meta.env.VITE_NEURON_API_TOKEN,
//   };

//   return (
//     <Provider config={neuronConfig} configName={configName}>
//       {children}
//     </Provider>
//   );
// }
