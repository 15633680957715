import {
  AFFILIATE_DECLATION_DEFAULT_TEXT,
  SHOW_DEBUG_INFO,
} from "@app/constants";
import { getEmbedUri } from "@app/utils/embed";
import { extractDynamicIframe } from "@app/utils/extractDynamicIframe";
import ProductContent from "@components/Modules/Product/ProductContentElement";
import ProductIndex from "@components/Modules/Product/ProductIndex";
import {
  configAffiliatesDeclaration,
  configGAM,
  configSingleStory,
} from "@pub/config";
import {
  Ads,
  CallOnQuote,
  Divider,
  ResponsiveIframe,
  SponsorshipBox,
  TableOfContents,
  Typography,
} from "@sphtech/dmg-design-system";
import { Fragment } from "react";

import {
  isCUEImageElement,
  ResolvedCUEElement,
  resolveImageCrop,
} from "./elements";
import { Annotation } from "./StorylineElements/Annotation/Annotation";
import StorylineBeforeAndAfter from "./StorylineElements/BeforeAndAfter";
import CallToAction from "./StorylineElements/CallToAction";
import { ColumnImage } from "./StorylineElements/ColumnImage";
import CustomHtml from "./StorylineElements/CustomHtml";
import Gallery from "./StorylineElements/Gallery/Gallery";
import StorylineImage from "./StorylineElements/Image";
import List from "./StorylineElements/List";
import NestedLayout from "./StorylineElements/NestedLayout";
import PromoBoxElement from "./StorylineElements/PromoBoxElement";
import { EmbeddedGalleryInView } from "./StorylineElements/Relation/EmbeddedGallery";
import GroupedRelation from "./StorylineElements/Relation/GroupedRelation";
import SubHeadingElement from "./StorylineElements/SubHeadingElement";
import Table from "./StorylineElements/Table/Table";
import {
  extractAnchor,
  getTableOfContentsItems,
  isSubHead,
  isTableOfContents,
} from "./StorylineElements/tableOfContentsUtils";

const { Body } = Typography;

type StorylineProps = {
  elements: ResolvedCUEElement[];
  path: string;
  bodyMaxWidth: number;
  excludeAds?: boolean;
};

const Storyline = ({
  elements,
  path,
  bodyMaxWidth,
  excludeAds = false,
}: StorylineProps) => {
  let paragraphCount = 0;
  let adInsertMax = configGAM.paragraphInsertMax;
  const adInsertPos = configGAM.paragraphInsertPos;
  const adGalleryInsertPos = configGAM.galleryInsertPos;
  return (
    <>
      {elements.map((element, index, allElements) => {
        if (element.type === "paragraph") {
          const htmlAnchor = element.additionalFields.htmlAnchor?.value;
          const bInsertAd =
            paragraphCount > 0 &&
            paragraphCount % adInsertPos == 0 &&
            adInsertMax > 0;
          if (bInsertAd) {
            adInsertMax--;
          }
          paragraphCount++;
          return (
            <Fragment key={index}>
              {!excludeAds && bInsertAd && <Ads.AdsMidArticle />}

              <Body id={htmlAnchor}>
                <Annotation element={element} />
              </Body>
            </Fragment>
          );
        }
        if (isSubHead(element)) {
          if (isTableOfContents(element)) {
            const items = getTableOfContentsItems(allElements);
            return (
              <TableOfContents
                key={index}
                title={
                  <Annotation
                    element={element.additionalFields.subhead || element}
                  />
                }
                items={items.map((element) => ({
                  label: (
                    <Annotation
                      element={element.additionalFields.subhead || element}
                    />
                  ),
                  href: `#${extractAnchor(element)}`,
                }))}
                // https://sph.atlassian.net/browse/DMG-4337
                prenumbered={items[0]?.additionalFields.subhead?.value.startsWith(
                  "1. ",
                )}
              />
            );
          }
          return <SubHeadingElement key={index} element={element} />;
        }
        if (element.type === "image") {
          return (
            <StorylineImage
              key={index}
              element={element}
              bodyMaxWidth={bodyMaxWidth}
            />
          );
        }
        if (element.type === "image_compare") {
          return (
            <StorylineBeforeAndAfter
              key={index}
              element={element}
              bodyMaxWidth={bodyMaxWidth}
            />
          );
        }
        if (element.type === "embed") {
          return <CustomHtml key={index} element={element} />;
        }
        if (element.type === "gallery_items") {
          let startcount = 0;
          if (paragraphCount >= adInsertPos) {
            startcount = paragraphCount % adInsertPos == 0 ? 2 : 1;
          }
          const numChildren = element.children?.length || 0;
          //less the leftover slides
          paragraphCount += numChildren - (numChildren % adGalleryInsertPos);
          return (
            <Gallery
              key={index}
              element={element}
              path={path}
              startcount={startcount}
            />
          );
        }
        if (element.type === "pull_quote") {
          return (
            <CallOnQuote
              key={index}
              label={element.additionalFields.quoteLabel?.value}
              title={
                element.additionalFields.quote && (
                  <Annotation element={element.additionalFields.quote} />
                )
              }
              description={element.additionalFields.quotee?.value}
            />
          );
        }
        if (element.type === "sponsorship_box") {
          const info = element.children?.find(
            (child) => child.type === "sponsorship_box_info",
          );
          const image = element.children?.find(
            (child) => child.type === "image",
          );
          const crop = isCUEImageElement(image)
            ? resolveImageCrop(image, "original")
            : undefined;
          return (
            <SponsorshipBox
              key={index}
              title={info?.additionalFields.title?.value}
              description={info?.additionalFields.description?.value}
              image={crop}
              ctaUrl={info?.additionalFields.cta_url?.value}
              ctaText={info?.additionalFields.cta_text?.value}
              showCtaIcon={configSingleStory.sponsorshipBox?.showCtaIcon}
            />
          );
        }

        if (
          element.type === "relation" &&
          element.relation?.type === "embeddedGallery" &&
          element.relation.id
        ) {
          return (
            <EmbeddedGalleryInView
              key={index}
              id={element.relation.id}
              path={path}
            />
          );
        }
        if (element.type === "grouped_relation") {
          return <GroupedRelation key={index} elements={element} />;
        }
        if (element.type === "column_images") {
          return (
            <ColumnImage
              key={index}
              element={element}
              bodyMaxWidth={bodyMaxWidth}
            />
          );
        }
        if (element.type === "table") {
          return <Table key={index} element={element} />;
        }
        if (
          element.type === "call_to_action" ||
          element.type === "shop_button"
        ) {
          return (
            <CallToAction
              key={index}
              element={element}
              variant="secondary"
              bodyMaxWidth={bodyMaxWidth}
            />
          );
        }

        if (element.type === "affiliate_declaration") {
          const affiliateInfo = element.children?.find(
            (child) => child.type === "affiliate_declaration_info",
          );
          return (
            !!affiliateInfo?.additionalFields.declaration?.value && (
              <Body key={index}>
                <b>
                  {affiliateInfo.additionalFields.text?.value ||
                    configAffiliatesDeclaration.text ||
                    AFFILIATE_DECLATION_DEFAULT_TEXT}
                </b>
              </Body>
            )
          );
        }

        if (
          element.type === "list_bulleted" ||
          element.type === "list_numbered"
        ) {
          return <List key={index} element={element} />;
        }

        if (
          element.type === "youtube_video" ||
          element.type === "brightcove_video"
        ) {
          const title = element.additionalFields.title?.value || "";
          const videoUrl = getEmbedUri(element);
          return (
            !!videoUrl && (
              <ResponsiveIframe title={title} src={videoUrl} loading="lazy" />
            )
          );
        }

        if (element.type === "promo_box") {
          return <PromoBoxElement key={index} element={element} />;
        }

        if (element.type === "product_listing") {
          return (
            <ProductIndex
              key={index}
              element={element}
              allElements={allElements}
            />
          );
        }

        if (
          element.type === "relation" &&
          element.relation?.type === "product" &&
          element.relation.id
        ) {
          return <ProductContent key={index} element={element} />;
        }

        if (element.type === "horizontal_row") {
          return <Divider key={index} variant="story" />;
        }

        if (element.type === "dynamic_iframe_wrapper") {
          const iframeData = extractDynamicIframe(
            element.additionalFields.dynamicIframe?.value,
          );
          return iframeData && <ResponsiveIframe key={index} {...iframeData} />;
        }

        if (element.type === "nested_elements" && element.children) {
          // Recursive Storyline component
          return (
            <NestedLayout key={index}>
              <Storyline
                path={path}
                elements={element.children}
                bodyMaxWidth={bodyMaxWidth}
              />
            </NestedLayout>
          );
        }

        if (SHOW_DEBUG_INFO) {
          return (
            <details key={index}>
              <summary>
                <code>Unimplemented block {element.type}</code>
              </summary>
              <pre style={{ overflow: "auto" }}>
                {JSON.stringify(element, null, 2)}
              </pre>
            </details>
          );
        }
        return null;
      })}
    </>
  );
};

export default Storyline;
