import { useCallback, useReducer, useRef } from "react";
import {
  SwipeEventData,
  SwipeableHandlers,
  useSwipeable,
} from "react-swipeable";
import { useMediaQuery } from "../index";

enum ENavigationAction {
  Next = "next",
  Prev = "prev",
  Goto = "goto",
}

type NavigationAction = {
  type: ENavigationAction;
  payload: number;
};

type NavigationState = {
  position: number;
};

// eslint-disable-next-line react-refresh/only-export-components
function NavigationReducer(state: NavigationState, action: NavigationAction) {
  switch (action.type) {
    case "next": {
      return {
        ...state,
        position: action.payload,
      };
    }

    case "prev": {
      return {
        ...state,
        position: action.payload,
      };
    }

    case "goto": {
      return {
        ...state,
        position: action.payload,
      };
    }
  }
  throw Error("Unknown action: " + action.type);
}

function useCarousel(
  itemsLength: number,
  noOfItemsShow: number,
  gotoAnimation: boolean | undefined = true,
  alignCenter: boolean = false,
): {
  position: number;
  hasReachedEnd: boolean;
  hasSlideMoved: boolean;
  next: () => void;
  prev: () => void;
  goto: (index: number) => void;
  TouchHandlers: SwipeableHandlers;
  refPassthrough: (el: HTMLDivElement | null) => void;
} {
  const InitialState = {
    position: 0,
  };

  const [state, dispatch] = useReducer(NavigationReducer, InitialState);
  const mobileUp = useMediaQuery("screen and (min-width: 1000px)");
  const carouselElementRef = useRef<HTMLDivElement | null>(null);

  //if mobileUp we need to show noOfItemsShow items at a time.
  const endPosition = mobileUp
    ? Math.ceil(itemsLength / noOfItemsShow) - 1
    : itemsLength - 1;
  const hasReachedEnd = state.position === endPosition;

  const hasSlideMoved = state.position > 0;

  const handleNavigation = (direction: "next" | "prev") => {
    if (!carouselElementRef.current) {
      console.warn(
        "[useCarousel] refPassthrough not assigned before handleNavigation",
      );
      return;
    }

    const element = carouselElementRef.current;

    if (direction === "next") {
      const nextPosition = hasReachedEnd ? 0 : state.position + 1;

      dispatch({
        type: ENavigationAction.Next,
        payload: nextPosition,
      });

      carouselElementRef?.current?.scrollBy({
        left: scrollPosition(
          nextPosition,
          element,
          noOfItemsShow,
          mobileUp,
          alignCenter,
        ),
        behavior: "smooth",
      });
    } else if (direction === "prev") {
      const prevPosition = hasSlideMoved ? state.position - 1 : endPosition;

      dispatch({
        type: ENavigationAction.Prev,
        payload: prevPosition,
      });

      carouselElementRef?.current?.scrollBy({
        left: scrollPosition(
          prevPosition,
          element,
          noOfItemsShow,
          mobileUp,
          alignCenter,
        ),
        behavior: "smooth",
      });
    }
  };

  // This function is extracted from handleNavigation to avoid using eslint-disable-next-line
  const goto = useCallback(
    (goto: number) => {
      if (goto === undefined) throw Error("goto is undefined");
      dispatch({
        type: ENavigationAction.Goto,
        payload: goto,
      });
      carouselElementRef?.current?.scrollBy({
        left: scrollPosition(
          goto,
          carouselElementRef.current,
          noOfItemsShow,
          mobileUp,
        ),
        behavior: gotoAnimation ? "smooth" : "instant",
      });
    },
    [gotoAnimation, mobileUp, noOfItemsShow],
  );

  const next = () => handleNavigation("next");
  const prev = () => handleNavigation("prev");

  const TouchHandlers = useSwipeable({
    onSwiped: (eventData: SwipeEventData) => {
      if (eventData.dir === "Left") {
        next();
      } else {
        prev();
      }
    },
  });

  //workaround for react-swipeable ref
  const refPassthrough = (el: HTMLDivElement | null) => {
    TouchHandlers.ref(el);
    carouselElementRef.current = el;
  };

  return {
    position: state.position,
    hasReachedEnd,
    hasSlideMoved,
    next,
    prev,
    goto,
    TouchHandlers,
    refPassthrough,
  };
}

function scrollPosition(
  state: number,
  element: HTMLElement,
  noOfitemsToShow: number,
  mobileUp: boolean | undefined,
  alignCenter: boolean = false,
) {
  const outerElement = element;
  const innerItems =
    element.children.length > 1
      ? element.children
      : element.children[0]?.children;

  const currentNode = mobileUp
    ? innerItems?.[state * noOfitemsToShow]
    : innerItems?.[state];
  if (!currentNode) {
    return;
  }

  const currentNodeRect = currentNode.getBoundingClientRect();
  const outerRect = outerElement.getBoundingClientRect();

  // Calculate the relative left position of currentNode to outerNode
  const relativeLeft =
    currentNodeRect.left - outerRect.left + outerElement.scrollLeft;

  // Calculate the centering scroll offset
  const center = relativeLeft - outerRect.width / 2 + currentNodeRect.width / 2;

  // Calculate the difference between the desired center and the current scroll position
  const scrollToCenter = center - outerElement.scrollLeft;

  const scrollToStart =
    currentNodeRect && currentNodeRect?.left - outerRect?.left;

  return alignCenter && !mobileUp ? scrollToCenter : scrollToStart;
}

export default useCarousel;
