import { NoSSR } from "@sphtech/web2-core/components";
import {
  CustomContext,
  ResponseType,
  TRouteWithRedirect,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import React, { ReactElement } from "react";
import { useBodyScrollLock, useSidebarToggle, useSticky } from "../../../hooks";
import MobileBanner from "../../Block/MobileBanner/MobileBanner";
import {
  createHideMobileBannerSEF,
  setMobileBannerHiddenCookie,
} from "../../Block/MobileBanner/mobileBannerState";
import { SocialIconProps } from "../../Block/SocialIcon/SocialIcon";
import Footer from "../../Module/Footer";
import MegaMenu from "../../Module/MegaMenu/MegaMenu";
import MegaMenuPortal from "../../Module/MegaMenu/MegaMenuPortal";
import NavbarGrid from "../../Module/NavbarGrid/NavbarGrid";
import {
  BurgerMenuIcon,
  LoginIcon,
  SearchIcon,
} from "../../Theme/assets/icons/commonIcons";
import * as Ads from "../../Utility/Ads/Ads";
import { NoAds } from "../../Utility/Ads/AdsSettings";
import { RoutePathsType } from "../../Utility/dataTypes";
import styles from "./base.module.css";
import { GlobalBaseData } from "./types";
import { Helmet } from "react-helmet-async";
import { NavigationProps } from "../../Module/NavbarGrid/types";

type MegaMenuPropsType = React.ComponentProps<typeof MegaMenu>;

export type BaseLayoutProps = {
  ArticleTitle?: string;
  children: React.ReactNode;
  ads?: boolean;
  leaderboard?: boolean;
  NewsTicker?: React.ReactNode;
  RoutePaths: RoutePathsType;
  svgSource: string;
  footerSvgSource?: string;
  siteName: string;
  currentLocation: {
    pathname: string;
  };
  mciText?: React.ReactNode;
  svgHamburgerMenu?: ReactElement;
  socialIconProps?: SocialIconProps;
  megaMenuProps: Partial<MegaMenuPropsType>;
  subscribeBoxButtonIcon?: React.ReactNode;
  skipMobileBanner?: boolean;
  NavbarComponent?: typeof NavbarGrid;
  section?: string;
  sphLogoInverted?: boolean;
  footerWithDivider?: boolean;
  NewsLetterElement?: React.ReactNode;
  navbarDropdownOptions?: NavigationProps["navbarDropdownOptions"];
};

/**
 * Items extracted to the /Module/MegaMenu folder:
 * - MegaMenuPortal component
 * - socialMedia constants
 * - NewsLetterProps constants
 * - CloseButtonProps constants
 */
export default function BaseLayout({
  ArticleTitle,
  children,
  ads = true,
  leaderboard = true,
  NewsTicker, // <Newsticker /> passed as prop for HBS
  RoutePaths, // Routes to be provided by individual pub
  svgSource, // Logo to be provided by individual pub
  footerSvgSource, // Footer logo to be provided by individual pub
  siteName, // Used for alt text on Logo
  mciText,
  svgHamburgerMenu, // Used for different svg icon for hamburger menu
  socialIconProps,
  megaMenuProps,
  subscribeBoxButtonIcon,
  skipMobileBanner: skipMobileBannerProp,
  currentLocation,
  NavbarComponent = NavbarGrid,
  section,
  sphLogoInverted = false,
  footerWithDivider = true,
  NewsLetterElement,
  navbarDropdownOptions,
}: BaseLayoutProps): ReactElement {
  const logoSrc = `data:image/svg+xml;utf8,${encodeURIComponent(svgSource)}`;

  //used in female.
  const footerLogoSrc =
    footerSvgSource &&
    `data:image/svg+xml;utf8,${encodeURIComponent(footerSvgSource)}`;

  const [isOpen, toggleSidebar] = useSidebarToggle(false);
  const { sticky } = useSticky();
  useBodyScrollLock(isOpen);

  let magazineIssues;
  let navbarDropdown;
  let userSubmissionBox;
  // tmp woraround until fixed upstream in web2-core
  // - useRouteContext always throws if page is static
  try {
    const routeContext: CustomContext<
      TRouteWithRedirect<GlobalBaseData, GlobalBaseData>
      // eslint-disable-next-line react-hooks/rules-of-hooks
    > = useRouteContext();

    const { context: response } = routeContext;

    if (response.type !== ResponseType.REDIRECT) {
      magazineIssues = response.payload?.magazineIssues;
      navbarDropdown = response.payload?.navbarDropdown;
      userSubmissionBox = response.payload?.userSubmissionBox;
    }
  } catch (e) {
    // nada, e.g. catch all page
  }

  const NavbarObjectProps = {
    ArticleTitle: ArticleTitle,
    toggleSidebar: toggleSidebar,
    routes: RoutePaths.NavBar,
    LogoSource: logoSrc,
    MenuIcon: svgHamburgerMenu || BurgerMenuIcon,
    withSearch: true,
    SearchIcon: SearchIcon,
    LoginIcon: LoginIcon,
    // Customised in CUE and passed to data.ts
    dropdownTeasers: navbarDropdown?.teasers,
    section,
    navbarDropdownOptions,
  };

  // overwrite text and url from magazine issues config
  const magazineIssuesButton =
    RoutePaths.NavBar.utilityLinks.find(
      (link) => link.id === "magazineIssues",
    ) ||
    RoutePaths.NavBar.primaryLinks.find((link) => link.id === "magazineIssues");
  if (magazineIssuesButton && magazineIssues?.config?.navibar) {
    const { buttonText, buttonUrl } = magazineIssues.config.navibar;
    if (buttonText) {
      magazineIssuesButton.name = buttonText;
    }
    if (buttonUrl) {
      magazineIssuesButton.url = buttonUrl;
    }
  }

  const subscribeImageCrop =
    magazineIssues?.teasers?.[0] &&
    (magazineIssues.teasers[0].image?.crops.original ||
      magazineIssues.teasers[0].image?.crops.portrait);

  const magazineIssuesMegaMenu = {
    ...magazineIssues?.config?.megamenu,
    variant: magazineIssues?.config?.megamenu?.variant || "primary",
  };
  const mobileBannerConfig = magazineIssues?.config?.mobileBanner;
  const skipMobileBanner =
    skipMobileBannerProp ||
    (Array.isArray(mobileBannerConfig?.skipPaths) &&
      mobileBannerConfig.skipPaths.includes(currentLocation.pathname));
  const cuePrimaryLinks = magazineIssues?.config?.navibar?.links;
  const cueMegaMenuLinks = magazineIssuesMegaMenu?.links;

  if (cuePrimaryLinks) {
    const primaryLinks = RoutePaths.NavBar.primaryLinks;

    cuePrimaryLinks.forEach((primaryLink) => {
      if (!primaryLinks.find((link) => link.name === primaryLink.text)) {
        primaryLinks.splice(primaryLink.position || primaryLinks.length, 0, {
          ...primaryLink,
          name: primaryLink.text, //lets do re-mapping to assign text into name
        });
      }
    });
  }

  if (cueMegaMenuLinks) {
    const megaMenuLinks = RoutePaths.MegaMenu;

    cueMegaMenuLinks.forEach((megaMenuLink) => {
      if (!megaMenuLinks.find((link) => link.text === megaMenuLink.text)) {
        megaMenuLinks.splice(
          megaMenuLink.position || megaMenuLinks.length,
          0,
          megaMenuLink,
        );
      }
    });
  }

  return (
    <>
      {!ads && <NoAds />}
      <Helmet>
        <script>
          {createHideMobileBannerSEF(mobileBannerConfig?.cookieKey)}
        </script>
      </Helmet>
      <header className={styles.header}>
        {!sticky && NewsTicker}

        <NavbarComponent
          navObject={NavbarObjectProps}
          sticky={sticky}
          currentLocation={currentLocation}
        />
      </header>
      <NoSSR>
        <MegaMenuPortal
          isOpen={isOpen}
          toggleSidebar={toggleSidebar}
          SubscribeBoxProps={
            magazineIssuesMegaMenu?.title && magazineIssuesMegaMenu?.buttonUrl
              ? {
                  description: magazineIssuesMegaMenu.title,
                  buttonText: magazineIssuesMegaMenu.buttonText || "SUBSCRIBE",
                  buttonIcon:
                    magazineIssuesMegaMenu.icon === true &&
                    subscribeBoxButtonIcon,
                  image: subscribeImageCrop ? subscribeImageCrop : undefined,
                  url: magazineIssuesMegaMenu.buttonUrl,
                  variant: magazineIssuesMegaMenu.variant,
                }
              : undefined
          }
          RoutePaths={RoutePaths}
          socialIconProps={socialIconProps}
          megaMenuProps={{
            ...megaMenuProps,
            userSubmissionBox,
            ContentHubTeasers: navbarDropdown,
          }}
        />
      </NoSSR>
      <main>
        {mobileBannerConfig?.url && !skipMobileBanner && (
          <MobileBanner
            href={mobileBannerConfig.url}
            onClose={() => {
              setMobileBannerHiddenCookie(7, mobileBannerConfig?.cookieKey);
            }}
            dangerouslySetInnerHTML={{
              __html: mobileBannerConfig.safeHtml || "",
            }}
            contentListOpts={{
              backgroundColor: mobileBannerConfig.backgroundColor,
              color: mobileBannerConfig.color,
              showOnDesktop: mobileBannerConfig.showOnDesktop,
            }}
          />
        )}
        {ads && leaderboard && <Ads.AdsLeaderboard />}
        {children}
      </main>
      <Footer
        NewsletterElement={NewsLetterElement}
        withDivider={footerWithDivider}
        socialIconProps={socialIconProps}
        navLinks={RoutePaths.Footer}
        mciText={mciText}
        sphLogoInverted={sphLogoInverted}
        logoProps={{
          href: "/",
          src: footerLogoSrc || logoSrc,
          alt: siteName,
        }}
      />
    </>
  );
}
