import { ReactNode } from "react";
import styles from "./Tab.module.css";
import { NavLink } from "../Typography";

type TabProps = {
  label?: string;
  data?: {
    url: string;
    text: string;
  }[];
};

export default function Tab({ label = "", data }: TabProps): ReactNode {
  return (
    data && (
      <div className={styles.tabContainer}>
        {label && <span className={styles.tabLabel}>{label}</span>}

        <div className={styles.tabGroup}>
          {data.map((list, i) => {
            return (
              <NavLink
                key={i}
                href={list.url}
                customClassName={styles.tabGroupElement}
              >
                {list.text}
              </NavLink>
            );
          })}
        </div>
      </div>
    )
  );
}
