import { configHome, DataSource } from "@pub/config";
import placeholders from "@pub/placeholders";
import { Ads, Arrow, VideoGalleryLandscape } from "@sphtech/dmg-design-system";
import cx from "classnames";
import { ReactNode } from "react";

import styles from "../homelayout.module.css";
import { extractHomeContentData, HomeLayoutProps } from "./utils";

type Props = {
  data: HomeLayoutProps["data"];
  dataSource: DataSource;
};

export default function HomeVideoGalleryLandscape({
  data,
  dataSource,
}: Props): ReactNode {
  const ContentVideoBlock = extractHomeContentData(data, dataSource);

  return (
    ContentVideoBlock &&
    ContentVideoBlock.items.length && (
      <div
        className={cx(
          styles.backgroundFill,
          styles.gutter,
          styles.videoGalleryLandscapeContainer,
          styles.videoGalleryColorOverride,
        )}
      >
        <div className={styles.bodyContainer}>
          <VideoGalleryLandscape
            title={ContentVideoBlock.title}
            items={ContentVideoBlock.items.map((item) => {
              const crop =
                item.image?.crops.landscape || placeholders.landscape;
              return {
                ...crop,
                videoUrl: item.embedUri,
                sections: item.sections,
                caption: item.title,
                captionUrl: item.path,
                description: item.description,
              };
            })}
            heading={{
              leftIcon: <Arrow size={22} direction="left" />,
              rightIcon: <Arrow size={22} />,
              withDivider: configHome.headingDivider,
              hasArrows: configHome.videoGalleryLandscape?.heading?.hasArrows,
            }}
            mobileLoopingArrowsBelow={{
              showArrows:
                configHome.videoGalleryLandscape?.spotLight
                  ?.mobileLoopingArrowsBelow,
              leftIcon: <Arrow size={22} direction="left" />,
              rightIcon: <Arrow size={22} />,
            }}
            strip={
              configHome.videoGalleryLandscape?.strip && {
                dividerVariant:
                  configHome.videoGalleryLandscape.strip.dividerVariant,
              }
            }
            cta={{
              name:
                configHome.videoGalleryLandscape?.viewMore?.text ||
                "VIEW ALL VIDEOS",
              url:
                configHome.videoGalleryLandscape?.viewMore?.href || "/videos",
              icon: configHome.viewMore?.icon,
            }}
            Imu={<Ads.AdsSideBar />}
            hasPagination={configHome.videoGalleryLandscape?.hasPagination}
          />
        </div>
      </div>
    )
  );
}
