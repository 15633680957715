import {
  processElements,
  ResolvedDealsElement,
  ResolvedProductInfo,
} from "../layouts/Article/elements";
import { CUEStoryContext } from "../types/Opensearch";
import { ProductData } from "../types/ServerCustomContext";
import { formatDiscount, formatPrice } from "./product";

export function extractProduct(data: CUEStoryContext) {
  const response = data;
  const resolvedElements = processElements(response.elements);

  const name = resolvedElements.bodyElements.find(
    (element): element is ResolvedProductInfo =>
      element.type === "product_info",
  );

  const deals = resolvedElements.bodyElements.filter(
    (element): element is ResolvedDealsElement => element.type === "deals",
  );

  return {
    id: response.id,
    name: resolvedElements.productName || "",
    header: name?.additionalFields.headerTitle?.value || "",
    image: resolvedElements.coverImage?.crops.original,
    discount: name?.additionalFields.discountText?.value || "",
    dealsSource: deals.map((deals) => ({
      name: deals.additionalFields?.sourceName?.value || "",
      url: deals.additionalFields?.destinationUrl?.value || "",
      discount: formatDiscount(deals.additionalFields?.discount?.value) || "",
      discountPrice:
        formatPrice(deals.additionalFields?.discountedPrice?.value) || "",
      dynamicPricing: deals.additionalFields?.dynamicPricing?.value || "",
    })),
    pros: resolvedElements.bodyElements
      .filter((element) => element.type === "product_pro")
      .flatMap((element) =>
        element.children?.flatMap((child) => child.children),
      ),
    cons: resolvedElements.bodyElements
      .filter((element) => element.type === "product_con")
      .flatMap((element) =>
        element.children?.flatMap((child) => child.children),
      ),
  };
}

export function isProductItem(
  item?: Error | ProductData | null,
): item is ProductData {
  return !!item && Object.prototype.hasOwnProperty.call(item, "id");
}
