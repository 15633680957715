import {
  StoryData,
  StoryDataApiResponse,
} from "@app/types/ServerCustomContext";
import { useInView } from "@components/hooks/hook";
import { Loader } from "@sphtech/dmg-design-system";
import { LOAD_MORE_TEXT } from "@src/pubs/common/config";
import { ReactNode, useEffect } from "react";
import useSWRInfinite from "swr/infinite";

import SinglePostLayout, { SinglePostProps } from "./SinglePostLayout";

const LIMIT = 5;

export default function ArticleInfiniteScroll({
  storyData,
  onInView,
}: SinglePostProps): ReactNode {
  const { id, sections } = storyData;
  const { ref, inView } = useInView({ threshold: 1 });

  const splitsections = sections.map((section) => section.uniqueName).join(",");

  const { data, isLoading, size, setSize } =
    useSWRInfinite<StoryDataApiResponse>(
      (index) =>
        `/_plat/api/story/latest?uniqSectionNames=${encodeURIComponent(
          splitsections,
        )}&from=${index * LIMIT}&size=${LIMIT}&ignoreIds=${encodeURIComponent(
          id,
        )}`,
      {
        initialSize: 0,
      },
    );

  const mergedData =
    data &&
    data.filter(Boolean).reduce<StoryData[]>((acc, item) => {
      return [...acc, ...(item?.results || [])];
    }, []);

  const isLoadingMore =
    isLoading || (size > 0 && data && typeof data[size - 1] === "undefined");

  // <Post storyData={relatedArticle} hideContent={true} key={index} />;

  const hasMoreData = size === 0 || !!data?.[size - 1]?.results?.length;
  useEffect(() => {
    if (!inView || !hasMoreData) return;
    void setSize((prev) => prev + 1);
  }, [inView, setSize, hasMoreData]);

  return (
    <>
      {mergedData &&
        !!mergedData.length &&
        mergedData.map((relatedArticle, index) => (
          <SinglePostLayout
            storyData={relatedArticle}
            hideContent={true}
            onInView={onInView}
            key={relatedArticle.id || index}
            infiniteScrolling={true}
          />
        ))}
      <div ref={ref}>{isLoadingMore && <Loader label={LOAD_MORE_TEXT} />}</div>
    </>
  );
}
