import { Typography, useCustomClassContext } from "@sphtech/dmg-design-system";
import cx from "classnames";
import { ReactElement } from "react";

import styles from "../contenthub.module.css";

const { Heading } = Typography;

type ContentHubTitleProps = {
  title: string;
};

/**
 * ContentHubTitle
 * Description: [Description of what the component does]
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function ContentHubTitle({
  title,
}: ContentHubTitleProps): ReactElement {
  const customClass = useCustomClassContext();
  return (
    <div
      className={cx(
        styles.articleMastheadTextContainer,
        customClass?.contentHubLayout?.articleMastHeadText,
      )}
    >
      <div className={styles.articleMastheadTitleWrapper}>
        <div className={styles.articleMastheadTitle}>
          <Heading.SectionTitle>{title}</Heading.SectionTitle>
        </div>
      </div>
    </div>
  );
}
