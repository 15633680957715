import { StoryData } from "@app/types/ServerCustomContext";
import { configSingleStory, configTag } from "@pub/config";
import {
  TagsButton,
  Typography,
  useCustomClassContext,
} from "@sphtech/dmg-design-system";
import cx from "classnames";

import styles from "./SinglePostLayout.module.css";

const { Heading } = Typography;

export default function TagContainer({
  tags,
  variant,
}: {
  tags: StoryData["tags"];
  variant?: "bottom";
}) {
  const customClass = useCustomClassContext();

  if (!tags.length) {
    return;
  }

  return (
    <div
      className={cx(
        styles.tagsContainer,
        variant === "bottom" && styles.tagsContainerBottom,
      )}
    >
      {configSingleStory.displayTags?.displayTagHeading && (
        <Heading.SubHeading3>
          {configSingleStory.displayTags.label || "Tags"}
        </Heading.SubHeading3>
      )}

      <div className={cx(styles.tagsItem, customClass?.tags)}>
        {tags.map((tag, index) => {
          const tagButton = (
            <TagsButton key={index} href={tag.path}>
              {tag.name}
            </TagsButton>
          );

          return configTag.itemSeparator && index !== tags.length - 1 ? (
            <span key={index}>
              {tagButton}
              {configTag.itemSeparator}
            </span>
          ) : (
            tagButton
          );
        })}
      </div>
    </div>
  );
}
