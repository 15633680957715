import { configSite } from "@pub/config";
import { Fragment } from "react";

const replacementParts: Record<string, React.JSX.Element | string> = {
  "\n": <br />,
  ...configSite.charWrappers,
};

/**
 * More information on regex used to break text:
 * https://stackoverflow.com/questions/12001953/javascript-and-regex-split-string-and-keep-the-separator
 */
export const breakAndReplace = (text: string) => {
  const delimiters = Object.keys(replacementParts);

  // This regex with the brackets (...)/g keeps delimiters after text.split()
  const delimitersReg = new RegExp(`(${delimiters.join("|")})`, "g");
  const splitResult = text.split(delimitersReg);

  // The delimiters are swapped out with the replacementParts here
  const mappedResult = splitResult.map((text, i) => {
    if (replacementParts[text]) {
      return <Fragment key={i}>{replacementParts[text]}</Fragment>;
    }
    return text;
  });

  return mappedResult;
};
