import SearchForm from "@components/Modules/SearchForm/SearchForm";
import { configError } from "@pub/config";
import {
  ResponsiveImage,
  useCustomClassContext,
} from "@sphtech/dmg-design-system";
import { Typography } from "@sphtech/dmg-design-system";
import cx from "classnames";
import React from "react";

import styles from "./ErrorLayout.module.css";
import type { PossibleText } from "./ErrorLayoutTypes";

const { Heading, Body } = Typography;

const ErrorLayoutContainer: React.FC<{
  text: PossibleText;
  statusCode: number;
}> = ({ text, statusCode }) => {
  const customClass = useCustomClassContext();

  return (
    <div
      className={cx(
        styles.gutterHorizontal,
        styles.gutterVertical,
        styles.notFoundTopContainerBackground,
        customClass?.errorLayout?.container,
      )}
    >
      <div className={cx(styles.bodyContainer, styles.notFoundTopContainer)}>
        {configError.messages?.status404?.image && (
          <div className={styles.heroImage}>
            <ResponsiveImage
              alt="Safe Area"
              fill="cover"
              src={configError.messages.status404.image.src}
              srcHeight={configError.messages.status404.image.srcHeight}
              srcWidth={configError.messages.status404.image.srcWidth}
            />
          </div>
        )}

        <div
          className={cx(customClass?.errorLayout?.errorLayoutHeadingContainer)}
        >
          <Heading.Title>{text.title}</Heading.Title>
        </div>
        {text.altTitle && (
          <div
            className={cx(
              customClass?.errorLayout?.errorLayoutHeadingContainer,
            )}
          >
            <Heading.TitleAlt>{text.altTitle}</Heading.TitleAlt>
          </div>
        )}
        <Body>{text.lead}</Body>

        {statusCode === 404 && (
          <div className={styles.searchInputContainer}>
            <SearchForm
              value=""
              onSubmit={(fieldValue) => {
                window.location.assign(
                  `/search/?q=${encodeURIComponent(fieldValue)}`,
                );
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ErrorLayoutContainer;
