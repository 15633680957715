import { DataSource } from "@pub/config";
import cx from "classnames";
import { ReactNode } from "react";

import styles from "../homelayout.module.css";
import HomeContentBlockIA from "./HomeContentBlockIA";
import HomeContentBlockIB from "./HomeContentBlockIB";
import { extractHomeContentData, HomeLayoutProps } from "./utils";

type Props = {
  data: HomeLayoutProps["data"];
  dataSource: DataSource;
  alternateBg?: boolean;
};

/**
 * 2 variants
 * HomeContentBlockIA: 7-tile layout (Default)
 * HomeContentBlockIB: 3-tile layout
 */
export default function HomeContentBlockI({
  data,
  dataSource,
  alternateBg,
}: Props): ReactNode {
  const contentBlockI = extractHomeContentData(data, dataSource);
  if (!contentBlockI?.items[0]) return null;

  const blockVariant = contentBlockI.config?.variant;

  return (
    <div
      className={cx(
        alternateBg && styles.backgroundFill,
        alternateBg && styles.gutterTopBottom,
        styles.contentHubBlockIContainer,
      )}
    >
      <div
        className={cx(
          styles.bodyContainer,
          styles.gutter,
          styles.contentBlockOverrideColor,
        )}
      >
        {blockVariant === "B" ? (
          <HomeContentBlockIB contentBlockI={contentBlockI} />
        ) : (
          <HomeContentBlockIA contentBlockI={contentBlockI} />
        )}
      </div>
    </div>
  );
}
