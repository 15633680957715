import { useCallback, useEffect, useState } from "react";

function maybeParseJSON(value: string | null): unknown | undefined {
  if (!value) {
    return;
  }

  try {
    return JSON.parse(value);
  } catch (error) {
    console.error("Error parsing JSON", { value }, error);
    return;
  }
}

export function createLocalStorageHook<T>(
  key: string,
  initialValue: T,
  isT: (value: unknown) => value is T,
) {
  return function useLocalStorage(): [T, (value: T) => void] {
    const [localValue, setLocalValue] = useState<T>(initialValue);

    useEffect(() => {
      // get stored value from localStorage if it's available otherwise use the initialValue
      const storedValue = maybeParseJSON(localStorage.getItem(key));
      setLocalValue(isT(storedValue) ? storedValue : initialValue);

      // syncing the value across tabs
      const onStorage = (e: StorageEvent) => {
        if (e.key === key) {
          const parsedData = maybeParseJSON(e.newValue);
          setLocalValue(isT(parsedData) ? parsedData : initialValue);
        }
      };

      // subscribe to storage event
      window.addEventListener("storage", onStorage);

      return () => {
        window.removeEventListener("storage", onStorage);
      };
    }, []);

    const setValue = useCallback((newValue: T) => {
      setLocalValue(newValue);
      try {
        // remove key if value is undefined otherwise store the new value
        if (typeof newValue === "undefined") {
          localStorage.removeItem(key);
        } else {
          localStorage.setItem(key, JSON.stringify(newValue));
        }
      } catch (error) {
        console.error(`Error saving to localStorage`, { key, newValue }, error);
      }
    }, []);

    return [localValue, setValue];
  };
}
