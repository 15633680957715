import { configHome, configTeaser, DataSource } from "@pub/config";
import placeholders from "@pub/placeholders";
import {
  ContentBlock,
  CustomClassContext,
  LatestArticle,
  ResponsiveImage,
} from "@sphtech/dmg-design-system";
import { NaturalCarousel } from "@sphtech/dmg-design-system";
import cx from "classnames";
import { ReactNode, useContext } from "react";

import styles from "../homelayout.module.css";
import { extractHomeContentData, HomeLayoutProps } from "./utils";

type Props = {
  data: HomeLayoutProps["data"];
  dataSource: DataSource;
  alternateBg?: boolean;
};

// ContentBlock 3 items | content-hub | Content Block B (3 tiles)
export default function HomeContentBlockB({
  data,
  dataSource,
  alternateBg,
}: Props): ReactNode {
  const ContentBlockThreeItems = extractHomeContentData(data, dataSource);
  const customClass = useContext(CustomClassContext);

  const mappedItems =
    ContentBlockThreeItems &&
    ContentBlockThreeItems.items.map((contentBlockItem, index) => (
      <div
        key={index}
        className={cx(
          styles.content,
          configHome.contentBlockB?.itemCenteredIndex === index &&
            styles.itemCentered,
        )}
      >
        <LatestArticle
          {...contentBlockItem}
          mode={
            configHome.contentBlockB?.landscapeIndexes?.includes(index)
              ? "landscape"
              : "portrait"
          }
          mobileMode={configHome.contentBlockB?.mobileMode}
          placeholders={placeholders}
          landscapeImageWidth={
            configHome.contentBlockB?.landscapeImageWidth || 315
          }
          portraitImageWidth={
            configHome.contentBlockB?.portraitImageWidth || 315
          }
          displayPublishDate={configTeaser.publishDate}
        />
      </div>
    ));
  return (
    !!ContentBlockThreeItems?.items.length && (
      <div
        className={cx(
          alternateBg && styles.backgroundFill,
          styles.contentHubContainer,
          styles.contentBlockBContainer,
        )}
      >
        {ContentBlockThreeItems.backgroundImage && (
          <div className={styles.backgroundImage}>
            <ResponsiveImage
              {...ContentBlockThreeItems.backgroundImage}
              fill="cover"
              loading="lazy"
              displayWidth={1000}
              mobileUp={{
                ...ContentBlockThreeItems.backgroundImage,
                displayWidth: 1920,
              }}
            />
          </div>
        )}
        <div className={cx(styles.bodyContainer, styles.gutter)}>
          <ContentBlock
            heading={ContentBlockThreeItems.title}
            cta={
              !configHome.contentBlockB?.viewMore?.hide
                ? {
                    url: ContentBlockThreeItems.url,
                    name:
                      configHome.contentBlockB?.viewMore?.text || "VIEW ALL",
                    icon: configHome.viewMore?.icon,
                  }
                : undefined
            }
            isHeadingClickable={configHome.isHeadingClickable ?? true}
            withDivider={configHome.headingDivider}
          >
            <div
              className={cx(
                configHome.contentBlockB?.scrollable &&
                  styles.contentBlockBScrollabel,
              )}
            >
              <div
                className={cx(
                  customClass?.homeContentBlockB?.contentBlockContainer,
                  configHome.contentBlockB?.scrollable &&
                    customClass?.homeContentBlockB?.contentBlockScroll,
                  styles.contentBlockContainer,
                  styles.latestTeaserFontOverride,
                )}
              >
                {configHome.contentBlockB?.scrollable ? (
                  <NaturalCarousel
                    isLooping={false}
                    showArrowsOnMobile
                    arrowSize={configHome.contentBlockB.arrowSize ?? 40}
                  >
                    {mappedItems}
                  </NaturalCarousel>
                ) : (
                  mappedItems
                )}
              </div>
            </div>
          </ContentBlock>
        </div>
      </div>
    )
  );
}
