import { configHome, configTeaser } from "@pub/config";
import placeholders from "@pub/placeholders";
import {
  Button,
  ContentBlock,
  Divider,
  LatestArticle,
  SectionContext,
  Typography,
} from "@sphtech/dmg-design-system";

import { Annotation } from "../../Article/StorylineElements/Annotation/Annotation";
import styles from "../homelayout.module.css";
import { HomeContentBlocks } from "./utils";

const { Body } = Typography;

type Props = {
  contentBlockI: HomeContentBlocks;
};

/**
 * Description: Body, Button, Divider, 3-tile layout
 */
const HomeContentBlockIB = ({ contentBlockI }: Props) => {
  const blockDescription = contentBlockI.config?.desc;

  return (
    <ContentBlock
      inlineGutter={false}
      heading={contentBlockI.title}
      headingZH={contentBlockI.titleZH}
      cta={{
        // name: "VIEW ALL",
        url: contentBlockI.url,
        // icon: configHome.viewMore?.icon,
      }}
      withDivider={configHome.headingDivider}
      isHeadingClickable={configHome.isHeadingClickable}
    >
      <SectionContext.Provider value={{ uniqueName: undefined }}>
        {blockDescription && (
          <>
            <Body>
              <Annotation
                element={{
                  value: blockDescription,
                }}
              />
            </Body>
            <div className={styles.buttonContainer}>
              <Button variant="secondary" href={contentBlockI.url}>
                Read More
              </Button>
            </div>
            <div className={styles.dividerContainer}>
              <Divider />
            </div>
          </>
        )}

        <div className={styles.flexLayout}>
          {/* Limit display to max first 4 items */}
          {contentBlockI.items.slice(0, 4).map((item, index) => {
            return (
              <div key={index} className={styles.flexItem}>
                <LatestArticle
                  {...item}
                  mode="landscape"
                  mobileMode="landscape"
                  placeholders={placeholders}
                  displayPublishDate={configTeaser.publishDate}
                />
              </div>
            );
          })}
        </div>
      </SectionContext.Provider>
    </ContentBlock>
  );
};

export default HomeContentBlockIB;
