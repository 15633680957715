import { configBrightcove } from "@pub/config";
import { ResponsiveIframe } from "@sphtech/dmg-design-system";

import { ResolvedEmbedCUEElement } from "../Article/elements";
import { getParsedUrl } from "../Article/StorylineElements/utils";

export function getCoverIframeProps(
  coverEmbed?: ResolvedEmbedCUEElement,
): React.ComponentProps<typeof ResponsiveIframe> | undefined {
  if (!coverEmbed) {
    return;
  }
  let src = coverEmbed.additionalFields.uri?.value;
  if (!src) {
    return;
  }
  let forceAspectRatio;
  if (
    coverEmbed.additionalFields.width?.value &&
    coverEmbed.additionalFields.height?.value
  ) {
    forceAspectRatio =
      +coverEmbed.additionalFields.width.value /
      +coverEmbed.additionalFields.height.value;
  }
  if (
    configBrightcove.bgLoopPlayerId &&
    // ensure src point to our brightcove account
    src.startsWith(
      `https://players.brightcove.net/${configBrightcove.accountId}/`,
    )
  ) {
    const url = getParsedUrl(src);
    if (url) {
      // force usage of bg loop player
      url.pathname = `/${configBrightcove.accountId}/${configBrightcove.bgLoopPlayerId}_default/index.html`;
      src = url.toString();
    }
  }
  return {
    src,
    forceAspectRatio,
  };
}
