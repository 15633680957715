import {
  configAuthorProfile,
  configLatestStory,
  configSearch,
  configTeaser,
} from "@pub/config";
import placeholders from "@pub/placeholders";
import {
  Ads,
  LatestArticle,
  Loader,
  Typography,
} from "@sphtech/dmg-design-system";
import {} from "@sphtech/dmg-design-system";
import { useInView } from "@src/app/components/hooks/hook";
import { TeaserData } from "@src/app/types/ServerCustomContext";
import { LOAD_MORE_TEXT } from "@src/pubs/common/config";
import cx from "classnames";
import { ReactNode, useEffect } from "react";

import styles from "./authorprofilelayout.module.css";

const { Heading } = Typography;

type Props = {
  showHeading: boolean;
  name?: string;
  data?: TeaserData[];
  isLoadingMore: boolean;
  loadMore?: () => void;
};

function AuthorProfileTeaserLayout({
  showHeading,
  name,
  data,
  isLoadingMore,
  loadMore,
}: Props): ReactNode {
  const { ref, inView } = useInView({ threshold: 0.1 });
  useEffect(() => {
    if (inView && loadMore) {
      loadMore();
    }
  }, [inView, loadMore]);

  return (
    <>
      {!!data?.length && (
        <section className={styles.section} ref={ref}>
          {showHeading && configAuthorProfile.sectionHeadingText && (
            <div className={cx(styles.headingContainer)}>
              <Heading.SubHeading2>
                {configAuthorProfile.sectionHeadingText} {name}
              </Heading.SubHeading2>
            </div>
          )}
          <div className={styles.mainContentBlockContainer}>
            <div
              className={cx(
                styles.mainContentBlockLayout,
                configAuthorProfile.listingLayout &&
                  styles[
                    configAuthorProfile.listingLayout.mainContentBlockDisplay
                  ],
              )}
            >
              {data.map((teaser, index) => (
                <div
                  key={index}
                  className={cx(
                    styles.mainContentBlockItem,
                    configAuthorProfile.listingLayout &&
                      styles[
                        configAuthorProfile.listingLayout.mainContentBlockMode
                      ],
                  )}
                >
                  <LatestArticle
                    key={index}
                    {...teaser}
                    imageSize="small"
                    description={teaser.description}
                    mode={
                      configAuthorProfile.listingLayout
                        ? configAuthorProfile.listingLayout.mainContentBlockMode
                        : "landscape"
                    }
                    portraitImageWidth={configLatestStory.portraitImageWidth}
                    landscapeImageWidth={configLatestStory.landscapeImageWidth}
                    placeholders={placeholders}
                    displayPublishDate={configTeaser.publishDate}
                    mobileMode={configTeaser.mobileMode}
                  />
                </div>
              ))}
            </div>
            <div className={styles.sidebar}>
              <Ads.AdsSideBar />
            </div>
          </div>
        </section>
      )}
      {(loadMore || isLoadingMore) && (
        <div
          ref={ref}
          className={cx(styles.bodyContainer, styles.gutterBottom)}
        >
          {isLoadingMore && (
            <Loader label={configSearch.loadMoreText || LOAD_MORE_TEXT} />
          )}
        </div>
      )}
    </>
  );
}

export default AuthorProfileTeaserLayout;
