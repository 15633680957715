import { ComponentProps, ReactNode, useState } from "react";

import style from "./AiSummary.module.css";
import AiSummaryLightbox from "./AiSummaryLightbox";
import { Disclaimer } from "../../Element/Typography";
import AiSummaryStarRating from "./AiSummaryStarRating";
import { useBodyScrollLock } from "../../../hooks";
import { aiStarIcon } from "./AiSummaryIcon";
import { NoSSR } from "@sphtech/web2-core/components";

type AiSummaryProps = {
  data: ComponentProps<typeof AiSummaryLightbox>["data"];
  gaEventTracker?: ComponentProps<typeof AiSummaryLightbox>["gaEventTracker"];
  submitStarRating?: ComponentProps<
    typeof AiSummaryStarRating
  >["submitStarRating"];
};

export default function AiSummary({
  data,
  gaEventTracker,
  submitStarRating,
}: AiSummaryProps): ReactNode {
  const [isOpen, setIsOpen] = useState(false);
  useBodyScrollLock(isOpen);

  return (
    <>
      <div className={style.aiSummary}>
        <button
          className={style.aiSummaryButton}
          onClick={() => setIsOpen(true)}
        >
          <div className={style.aiSummaryIcon}>
            <div className={style.aiSummaryStarIcon}>{aiStarIcon}</div>
            <div className={style.aiSummaryButtonText}>At a Glance</div>
          </div>
          <Disclaimer Element="div">Generated by AI</Disclaimer>
        </button>
      </div>
      <NoSSR>
        <AiSummaryLightbox
          isOpen={isOpen}
          toggle={() => setIsOpen(!isOpen)}
          data={data}
          gaEventTracker={gaEventTracker}
          submitStarRating={submitStarRating}
        />
      </NoSSR>
    </>
  );
}
